import * as React from 'react';

import classNames from 'classnames/bind';
import cs from 'classnames';
import styles from './OfferDetails.scss';

import { useTranslation } from 'react-i18next';
import CollapsedOfferDetails from './CollapsedOfferDetails/CollapsedOfferDetails';
import { PriceOfferT } from 'common/store/order-creation/models';
import { useSelector } from 'react-redux';
import {
    ADDITIONAL_SERVICES_ID_MAP,
    CurrencyEnum,
    DEFAULT_ICON_SIZE,
    StyleGuideColorsEnum,
    UnitTypeEnum,
} from 'common/constants';
import {
    checkAvailableService,
    getFakeAdditionalServicesPrice,
    summaryAdditionalServicesPrice,
} from 'common/layouts/NewOrderPage/additional-services';
import RouteIcon from 'common/icons/RouteIcon';
import FuelIcon from 'common/icons/FuelIcon';
import { moneyOperations } from 'common/utils/money';
import RuleImpact from 'common/components/RuleImpact/RuleImpact';
import PartnersIcon from 'common/icons/PartnersIcon';
import LayoverIcon, { getLayoverIconProps } from 'common/icons/LayoverIcon';
import { ALL_TOLL_TYPES, CountryCodeT } from 'common/utils/api/models';
import Link, { LinkThemeEnum } from 'common/components/Link/Link';
import { selectAdditionalServicesById } from 'common/store/additional-services-dict/selectors';
import UnitTypeCount from 'common/components/units/UnitTypeCount/UnitTypeCount';
import Money from 'common/components/Money/Money';
import PriceDetailsHeader from 'common/layouts/NewOrderPage/OfferDetails/PriceDetailsHeader/PriceDetailsHeader';
import UrgentIcon from 'common/icons/UrgentIcon';
import { TOLL_MAP_T, TOLL_TOOLTIP_MAP_T } from 'common/components/TollLabel/TollLabel';
import isNil from 'lodash/isNil';
import keys from 'lodash/keys';
import isEmpty from 'lodash/isEmpty';
import AsyncCountryFormatter from 'design-system/components/InfoTable/formatters/AsyncCountryFormatter/AsyncCountryFormatter';
import PriceDetails, { PriceDetailT } from 'design-system/components/PriceDetails/PriceDetails';

import { useCommonPriceDetails } from 'common/components/PriceDetails/hook';
import FlagIcon from 'common/icons/FlagIcon/FlagIcon';
import AdditionalServiceLabel from 'common/components/AdditionalServiceLabel/AdditionalServiceLabel';
import { convertSecToHours } from 'common/utils/time';
import { checkShouldShowLayover } from 'common/components/PriceDetails/utils';
import TooltipContent, {
    TooltipContentThemeEnum,
} from 'design-system/components/Tooltip/TooltipContent/TooltipContent';
import isNumber from 'lodash/isNumber';

const cx = classNames.bind(styles);

type PropsT = {
    className?: string;
    offer?: PriceOfferT;
    onSelect?: (offer: PriceOfferT) => void;
    isSelectable?: boolean;
    isSelected?: boolean;
    selectedServicesIds: number[];
    scrollToAdditionalServices?: () => void;
    hasSelectedLane?: boolean;
};

const OfferDetails: React.FC<PropsT> = React.memo((props) => {
    const {
        className,
        offer,
        onSelect,
        isSelectable,
        isSelected,
        selectedServicesIds,
        scrollToAdditionalServices,
        hasSelectedLane,
    } = props;

    const { t } = useTranslation();

    const [isOpenDetails, toggleOpenDetails] = React.useState<boolean>(false);

    const additionalServicesById = useSelector(selectAdditionalServicesById);

    const selectedAdditionalServices = selectedServicesIds
        .map((id) => additionalServicesById?.[id])
        .filter((service) => {
            return service && checkAvailableService(service, offer);
        });

    const additionalServicesPrice = summaryAdditionalServicesPrice(selectedServicesIds, additionalServicesById, offer);
    const fakeAdditionalServicesPrice = getFakeAdditionalServicesPrice(selectedServicesIds, offer);

    const selectedAdditionalServicesList: Array<PriceDetailT | null> = selectedAdditionalServices.map((service) => {
        if (!service) {
            return null;
        }

        if (service.id === ADDITIONAL_SERVICES_ID_MAP.EURO6) {
            return {
                title: <AdditionalServiceLabel enumType={service.enum} />,
                price: offer?.priceComponents.green || service.cost,
            };
        }

        return {
            title: <AdditionalServiceLabel enumType={service.enum} />,
            price: service.cost,
        };
    });

    if (scrollToAdditionalServices) {
        selectedAdditionalServicesList.push({
            title: (
                <Link theme={LinkThemeEnum.boldBrandDark} onClick={scrollToAdditionalServices}>
                    {t('common:new-order-offers.add-services')}
                </Link>
            ),
        });
    }

    const commonPriceDetails = useCommonPriceDetails();

    const laneCostOfOperationPrices: Array<PriceDetailT | null> = React.useMemo(() => {
        if (!offer) {
            return [];
        }

        return [
            {
                ...commonPriceDetails.lineHaul,
                price: offer.priceComponents.lineHaul,
            },
        ];
    }, [offer, commonPriceDetails]);

    const laneOtherPrices: Array<PriceDetailT> = React.useMemo(() => {
        if (!offer || !offer?.priceComponents?.tranziitServiceFee) {
            return [];
        }

        return [
            {
                ...commonPriceDetails.tranziitServiceFee,
                price: offer.priceComponents.tranziitServiceFee,
            },
        ];
    }, [offer, commonPriceDetails]);

    const costOfOperationPrices: Array<PriceDetailT | null> = React.useMemo(() => {
        if (!offer) {
            return [];
        }

        return [
            {
                ...commonPriceDetails.lineHaul,
                price: offer.priceComponents.lineHaul,
            },
            {
                ...commonPriceDetails.getRoadTaxes(offer.co2),
                price: offer.priceComponents.toll,
                list: offer.priceComponents.tollByRoadType
                    ? [
                          {
                              list: ALL_TOLL_TYPES.map((tollType) => {
                                  const toll = offer.priceComponents.tollByRoadType?.[tollType];
                                  if (!toll) {
                                      return null;
                                  }

                                  const { cost, byCountry } = toll;
                                  if (isNil(cost)) {
                                      return null;
                                  }

                                  const tollTooltip = TOLL_TOOLTIP_MAP_T[tollType];

                                  return {
                                      title: t(TOLL_MAP_T[tollType]),
                                      tooltipNode: tollTooltip ? (
                                          <TooltipContent theme={TooltipContentThemeEnum.black} width={150} isCenter>
                                              {t(tollTooltip)}
                                          </TooltipContent>
                                      ) : null,
                                      price: cost,
                                      list: isEmpty(byCountry)
                                          ? undefined
                                          : keys(byCountry).map((countryCode) => {
                                                const cost = byCountry?.[countryCode];

                                                return {
                                                    iconNode: <FlagIcon countryCode={countryCode} />,
                                                    title: (
                                                        <AsyncCountryFormatter
                                                            countryCode={countryCode as CountryCodeT}
                                                        />
                                                    ),
                                                    price: cost,
                                                };
                                            }),
                                  };
                              }),
                          },
                      ]
                    : undefined,
            },
            {
                ...commonPriceDetails.fuelCost,
                price: offer.priceComponents.fuel,
                list: commonPriceDetails.getFuelCostsByCountryList(offer.priceComponents.fuelByCountry),
            },
            {
                ...commonPriceDetails.additionalServices,
                price: moneyOperations.plus(additionalServicesPrice, fakeAdditionalServicesPrice, CurrencyEnum.EUR),
                list: [
                    {
                        list: selectedAdditionalServicesList,
                    },
                ],
                initialOpen: true,
            },
            offer.priceComponents.urgentOverprice
                ? {
                      ...commonPriceDetails.urgentOverprice,
                      price: offer.priceComponents.urgentOverprice,
                  }
                : null,
            offer.priceComponents.greenOverprice
                ? {
                      ...commonPriceDetails.greenSurcharge,
                      price: offer.priceComponents.greenOverprice,
                  }
                : null,
            checkShouldShowLayover(offer.layoverSeconds, offer.priceComponents.layover)
                ? {
                      ...commonPriceDetails.getLayoverCost(offer.layoverSeconds),
                      price: offer.priceComponents.layover || 0,
                  }
                : null,
        ];
    }, [offer, selectedAdditionalServicesList, hasSelectedLane]);

    const otherPrices: Array<PriceDetailT> = React.useMemo(() => {
        if (!offer) {
            return [];
        }

        return [
            {
                ...commonPriceDetails.tranziitServiceFee,
                price: offer.priceComponents.tranziitServiceFee,
            },
        ];
    }, [offer]);

    const brokerPrices: Array<PriceDetailT> | null = React.useMemo(() => {
        if (!offer?.brokerInfo) {
            return null;
        }

        return [
            // {
            //     title: t('common:new-order-offers.offer.price.broker-info.matrix-rate'),
            //     // @ts-expect-error TODO wait backend
            //     price: offer.brokerInfo.matrixRate,
            //     icon: <RouteIcon fillColor={StyleGuideColorsEnum.slate} />,
            // },
            {
                title: t('common:new-order-offers.offer.price.broker-info.estimated-carrier-rate'),
                price: offer.priceComponents?.totalRate,
                iconNode: <RouteIcon fillColor={StyleGuideColorsEnum.slate} />,
            },
            {
                title: t('common:new-order-offers.offer.price.broker-info.min-line-haul-cost'),
                price: offer.brokerInfo.minLineHaul,
                iconNode: <RouteIcon fillColor={StyleGuideColorsEnum.slate} />,
                hasBottomPadding: true,
            },
            {
                title: t('common:new-order-offers.offer.price.broker-info.layover-rate-per-hour'),
                price: offer.priceComponents.layoverRate,
                iconNode: <LayoverIcon {...getLayoverIconProps('regular')} />,
            },
            {
                title: t('common:new-order-offers.offer.price.broker-info.layover-rate-per-day'),
                price: offer.brokerInfo.maxLayoverPerDay,
                iconNode: <LayoverIcon {...getLayoverIconProps('regular')} />,
            },
            {
                title: t('common:new-order-offers.offer.price.broker-info.layover-in-hours'),
                rightNode: isNumber(offer.layoverSeconds) ? convertSecToHours(offer.layoverSeconds) : null,
                iconNode: <LayoverIcon {...getLayoverIconProps('regular')} />,
                hasBottomPadding: true,
            },
            {
                title: t('common:new-order-offers.offer.price.broker-info.urgent-rate'),
                price: offer.priceComponents.urgentRate || 0,
                iconNode: <UrgentIcon size={DEFAULT_ICON_SIZE} fillColor={StyleGuideColorsEnum.slate} />,
                hasBottomPadding: true,
            },
            {
                title: t('common:new-order-offers.offer.price.broker-info.team-driver-rate-per-hour'),
                price: offer.priceComponents.driverCostPerHour || 0,
                iconNode: (
                    <PartnersIcon fillColor={StyleGuideColorsEnum.light} strokeColor={StyleGuideColorsEnum.slate} />
                ),
            },
            {
                title: t('common:new-order-offers.offer.price.broker-info.hours-with-second-driver'),
                rightNode: isNumber(offer.priceComponents.teamDriveHours) ? (
                    <span>{offer.priceComponents.teamDriveHours}</span>
                ) : null,
                iconNode: (
                    <PartnersIcon fillColor={StyleGuideColorsEnum.light} strokeColor={StyleGuideColorsEnum.slate} />
                ),
            },
            {
                title: t('common:new-order-offers.offer.price.broker-info.additional-payment-for-team-drive'),
                price: offer.priceComponents.teamDriveCost || 0,
                iconNode: (
                    <PartnersIcon fillColor={StyleGuideColorsEnum.light} strokeColor={StyleGuideColorsEnum.slate} />
                ),
                hasBottomPadding: true,
            },
            {
                title: t('common:new-order-offers.offer.price.broker-info.fuel-price'),
                rightNode: isNumber(offer.brokerInfo.fuelPrice) ? (
                    <UnitTypeCount
                        types={[UnitTypeEnum.euroAbbreviation, UnitTypeEnum.litersAbbreviation]}
                        count={offer.brokerInfo.fuelPrice}
                    />
                ) : null,
                iconNode: <FuelIcon strokeColor={StyleGuideColorsEnum.slate} fillColor={StyleGuideColorsEnum.light} />,
            },
            // {
            //     title: t('common:new-order-offers.offer.price.broker-info.fuel-consumption'),
            //     unit: (
            //         <UnitTypeCount
            //             types={[
            //                 UnitTypeEnum.litersAbbreviation,
            //                 UnitTypeEnum.oneHundredKilometersAbbreviation,
            //             ]}
            //             // @ts-expect-error TODO wait backend
            //             count={offer.brokerInfo.fuelConsumption}
            //         />
            //     ),
            //     icon: <FuelIcon strokeColor={StyleGuideColorsEnum.slate} fillColor={StyleGuideColorsEnum.light} />,
            //     hasBottomPadding: true,
            // },
            {
                title: t('common:new-order-offers.offer.price.broker-info.rules-applied'),
                list: offer.brokerInfo.appliedRules.map((appliedRule) => {
                    return {
                        title: appliedRule?.name,
                        unit: <RuleImpact value={appliedRule.value} impactStrategy={appliedRule.valueType} />,
                    };
                }),
            },
        ];
    }, [offer]);

    const handleSelect = (): void => {
        if (onSelect && offer) {
            onSelect(offer);
        }
    };

    const costOfOperationRate = offer ? (offer?.priceComponents?.costOfOperation || 0) / offer.distanceKm : null;

    if (!offer) {
        return (
            <div className={cs(cx('inner'), className)}>
                <div className={cx('placeholder')}>
                    <div className={cx('placeholder__column', 'placeholder__column--first')}>
                        <div className={cx('placeholder__box', 'placeholder__box--price')} />
                        <div className={cx('placeholder__box', 'placeholder__box--rate')} />
                    </div>
                    <div className={cx('placeholder__column', 'placeholder__column--second')} />
                    <div className={cx('placeholder__column', 'placeholder__column--third')}>
                        <div className={cx('placeholder__box', 'placeholder__box--dates')} />
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className={cs(cx('inner'), className)}>
            <CollapsedOfferDetails
                additionalServicesPrice={additionalServicesPrice}
                offer={offer}
                onSelect={handleSelect}
                onOpenDetails={() => toggleOpenDetails(!isOpenDetails)}
                isSelectable={isSelectable}
                isSelected={isSelected}
                isOpenDetails={isOpenDetails}
                isDisabled={offer?.isExpired}
            />
            {offer && isOpenDetails && (
                <div className={cx('price-details')}>
                    <PriceDetailsHeader
                        className={cx('price-details__header')}
                        title={t('common:new-order-offers.offer.price.cost-of-operation')}
                        rightNode={
                            <span className={cx('rates')}>
                                <Money currency={CurrencyEnum.EUR} amount={offer?.priceComponents?.costOfOperation} />
                                {' ('}
                                <UnitTypeCount
                                    types={[UnitTypeEnum.euroAbbreviation, UnitTypeEnum.kilometersAbbreviation]}
                                    count={costOfOperationRate}
                                />
                                )
                            </span>
                        }
                    />
                    {!!hasSelectedLane && (
                        <>
                            <div
                                className={cx('price-details__list', {
                                    'price-details__list--isLast': !laneOtherPrices?.length,
                                })}
                            >
                                <PriceDetails list={laneCostOfOperationPrices} />
                            </div>
                            {!!laneOtherPrices?.length && (
                                <div className={cx('price-details__list', 'price-details__list--isLast')}>
                                    <PriceDetails list={laneOtherPrices} />
                                </div>
                            )}
                        </>
                    )}
                    {!hasSelectedLane && (
                        <>
                            <div className={cx('price-details__list')}>
                                <PriceDetails list={costOfOperationPrices} />
                            </div>
                            <PriceDetailsHeader title={t('common:new-order-offers.offer.price.other-costs')} />
                            <div
                                className={cx('price-details__list', {
                                    'price-details__list--isLast': !brokerPrices,
                                })}
                            >
                                <PriceDetails list={otherPrices} />
                            </div>
                            {brokerPrices && (
                                <>
                                    <PriceDetailsHeader title={t('common:new-order-offers.offer.price.price-engine')} />
                                    <div className={cx('price-details__list', 'price-details__list--isLast')}>
                                        <PriceDetails list={brokerPrices} />
                                    </div>
                                </>
                            )}
                        </>
                    )}
                </div>
            )}
        </div>
    );
});

export default OfferDetails;
