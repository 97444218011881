import * as React from 'react';
import { useMemo } from 'react';

import classNames from 'classnames/bind';

import styles from './SpotRequestDetails.scss';
import InfoTable, { InfoTableRowT } from 'design-system/components/InfoTable/InfoTable';
import { useTranslation } from 'react-i18next';
import OrderRouteLocations from 'common/components/order-details/OrderRouteLocations/OrderRouteLocations';
import ExcludedCountries from 'common/components/ExcludedCountries/ExcludedCountries';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTrailersDict } from 'common/store/trailers-dict/actions';
import Link, { LinkThemeEnum } from 'common/components/Link/Link';
import { PartnerTypeEnum, StopTypeEnum } from 'common/utils/api/models';
import { selectSpotRequestDetails } from 'broker-admin/store/spot-request-details/selectors';
import { urlFactory } from 'broker-admin/utils/urls';
import CalendarIcon from 'common/icons/CalendarIcon';
import { DEFAULT_ICON_SIZE, StyleGuideColorsEnum, UnitTypeEnum } from 'common/constants';
import ContactIcon from 'common/icons/ContactIcon';
import TimeWindowIcon from 'common/icons/TimeWindowIcon';
import CarrierIcon from 'common/icons/CarrierIcon';
import CommentIcon from 'common/icons/CommentIcon';
import LayoverIcon from 'common/icons/LayoverIcon';
import RouteIcon from 'common/icons/RouteIcon';
import TrailerIcon from 'common/icons/TrailerIcon';
import EmissionIcon from 'common/icons/EmissionIcon';
import { convertSecToHours, formatDate, formatTimeInterval } from 'common/utils/time';
import TeamDrivePill from 'common/components/status-pill/TeamDrivePill/TeamDrivePill';
import UnitTypeCount from 'common/components/units/UnitTypeCount/UnitTypeCount';
import { convertToKm } from 'common/utils/distance';
import { ApiSpotStopT, SpotBidT, SpotRequestStatusEnum } from 'broker-admin/utils/api/spot-broker-tranziit/models';

import Bids from 'broker-admin/components/BaseSpotRequestDetailsSidebarContent/SpotRequestDetails/Bids/Bids';
import ShipperIcon from 'common/icons/ShipperIcon';
import ShipperPriceOfferInfoTable, {
    ShipperPriceOfferInfoTablePropsT,
} from 'common/components/info-tables/ShipperPriceOfferInfoTable/ShipperPriceOfferInfoTable';
import CarrierCostPriceInfoTable from 'broker-admin/components/info-tables/CarrierCostPriceInfoTable/CarrierCostPriceInfoTable';
import { isNonNil } from 'common/utils';
import isNil from 'lodash/isNil';
import NumberIcon from 'common/icons/NumberIcon';
import LocationLabel from 'common/components/LocationLabel/LocationLabel';
import ShipmentDetailsCard from './ShipmentDetailsCard/ShipmentDetailsCard';
import SpotRequestDetailsTabs from './SpotRequestDetailsTabs/SpotRequestDetailsTabs';
import { SpotDetailsTabEnum } from 'broker-admin/components/BaseSpotRequestDetailsSidebarContent/SpotRequestDetails/models';
import EmissionClassFormatter from 'design-system/components/InfoTable/formatters/EmissionClassFormatter/EmissionClassFormatter';
import PillLabel, { PillLabelThemeEnum } from 'common/components/PillLabel/PillLabel';
import Card from 'design-system/components/Card/Card';
import keyBy from 'lodash/keyBy';
import SimpleTrailerTypeFormatter from 'design-system/components/InfoTable/formatters/SimpleTrailerTypeFormatter/SimpleTrailerTypeFormatter';

type PropsT = {
    id: SpotRequestIdT;
    isDisabledSelectBid?: boolean;
    selectedBidId: BidIdT | null;
    onSelectBid: (bid: SpotBidT) => void;
    onOpenUserDetails: (params: {
        partnerType: PartnerTypeEnum;
        partnerId: PartnerIdT;
        userId: UserIdT | null;
    }) => void;
};

const cx = classNames.bind(styles);

const PLACED_DATE_FORMAT = 'DD MMM YYYY, HH:mm';
const EXPIRES_DATE_FORMAT = 'DD MMM YYYY, HH:mm';

const SpotRequestDetails: React.FC<PropsT> = React.memo((props) => {
    const { id, selectedBidId, isDisabledSelectBid, onSelectBid, onOpenUserDetails } = props;

    const [selectedTab, setSelectedTab] = React.useState<SpotDetailsTabEnum | null>(SpotDetailsTabEnum.details);

    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(fetchTrailersDict());
    }, []);

    const { details } = useSelector(selectSpotRequestDetails(id));

    const stopById = React.useMemo(() => {
        return keyBy(details?.stops, 'id');
    }, [details]);

    const { t } = useTranslation();

    const entriesInfoTableRows = React.useMemo((): Array<InfoTableRowT> => {
        return [
            {
                icon: <ShipperIcon size={DEFAULT_ICON_SIZE} strokeColor={StyleGuideColorsEnum.gray} />,
                name: t('spot-request-details.details.shipper'),
                value: (
                    <Link
                        to={urlFactory.partnerDetails(PartnerTypeEnum.shipper, details?.shipper?.id)}
                        theme={LinkThemeEnum.boldBrandDark}
                    >
                        {details?.shipper?.name}
                    </Link>
                ),
                emptyValue: t('common:info-table.placeholders.not-specified'),
                isBoldValue: true,
                testSelector: 'shipper',
                rows: [
                    {
                        icon: null,
                        name: t('spot-request-details.details.dispatch-id'),
                        value: (
                            <Link
                                to={urlFactory.dispatchDetails(details?.dispatchId || '-')}
                                theme={LinkThemeEnum.boldBrandDark}
                            >
                                {details?.dispatchNumber}
                            </Link>
                        ),
                        emptyValue: t('common:info-table.placeholders.empty'),
                        isBoldValue: true,
                        testSelector: 'dispatch',
                    },
                ],
            },
        ].filter(isNonNil);
    }, [t, details]);

    const entriesInfoTableRows2 = React.useMemo((): Array<InfoTableRowT> => {
        return [
            {
                icon: <CarrierIcon size={DEFAULT_ICON_SIZE} strokeColor={StyleGuideColorsEnum.gray} />,
                name: t('spot-request-details.details.carrier'),
                value: details?.carrier?.id ? (
                    <Link
                        to={urlFactory.partnerDetails(PartnerTypeEnum.carrier, details.carrier.id)}
                        theme={LinkThemeEnum.boldBrandDark}
                    >
                        {details?.carrier.name}
                    </Link>
                ) : null,
                emptyValue: t('common:info-table.placeholders.carrier-not-assigned'),
                isBoldValue: true,
                testSelector: 'carrier',
                rows: [
                    details?.transportationOrderId
                        ? {
                              icon: null,
                              name: t('spot-request-details.details.transport-order'),
                              value: (
                                  <Link
                                      to={urlFactory.partnerTransportOrderDetails(
                                          PartnerTypeEnum.carrier,
                                          details?.carrier?.id || '-',
                                          details?.transportationOrderId || '-',
                                      )}
                                      theme={LinkThemeEnum.boldBrandDark}
                                  >
                                      {details?.transportationOrderNumber}
                                  </Link>
                              ),
                              emptyValue: t('common:info-table.placeholders.empty'),
                              isBoldValue: true,
                              testSelector: 'transport-order',
                          }
                        : null,
                ],
            },
        ].filter(isNonNil);
    }, [t, details]);

    const spotInfoTableRows = React.useMemo((): Array<InfoTableRowT> => {
        return [
            {
                icon: (
                    <CalendarIcon
                        size={DEFAULT_ICON_SIZE}
                        fillColor={StyleGuideColorsEnum.light}
                        strokeColor={StyleGuideColorsEnum.gray}
                    />
                ),
                name: t('spot-request-details.details.created'),
                value: formatDate(PLACED_DATE_FORMAT, details?.placedOn),
                emptyValue: t('common:info-table.placeholders.empty'),
                isBoldValue: true,
                testSelector: 'created',
                rows: [
                    {
                        icon: (
                            <ContactIcon
                                fillColor={StyleGuideColorsEnum.light}
                                strokeColor={StyleGuideColorsEnum.gray}
                            />
                        ),
                        name: t('spot-request-details.details.author'),
                        value: (
                            <Link
                                onClick={() => {
                                    onOpenUserDetails({
                                        partnerType: PartnerTypeEnum.carrier,
                                        partnerId: details?.carrier?.id || '-',
                                        userId: details?.createdBy?.id || '-',
                                    });
                                }}
                                theme={LinkThemeEnum.boldBrandDark}
                            >
                                {details?.createdBy?.fullName}
                            </Link>
                        ),
                        emptyValue: t('common:info-table.placeholders.empty'),
                        isBoldValue: true,
                        testSelector: 'author',
                    },
                    {
                        icon: (
                            <TimeWindowIcon
                                fillColor={StyleGuideColorsEnum.light}
                                strokeColor={StyleGuideColorsEnum.gray}
                            />
                        ),
                        name: t('spot-request-details.details.expiration'),
                        value: formatDate(EXPIRES_DATE_FORMAT, details?.expiresAt),
                        emptyValue: t('common:info-table.placeholders.empty'),
                        isBoldValue: true,
                        testSelector: 'expiration',
                    },
                ],
            },
        ];
    }, [t, details]);

    const shipmentInfoTableRows = React.useMemo((): Array<InfoTableRowT> => {
        return [
            details?.carrierCost?.layoverSeconds
                ? {
                      icon: (
                          <LayoverIcon fillColor={StyleGuideColorsEnum.light} strokeColor={StyleGuideColorsEnum.gray} />
                      ),
                      name: t('spot-request-details.details.layover'),
                      value: (
                          <UnitTypeCount
                              type={UnitTypeEnum.hoursAbbreviation}
                              count={convertSecToHours(details?.carrierCost?.layoverSeconds || 0)}
                          />
                      ),
                      isBoldValue: true,
                      testSelector: 'layover',
                      hasBottomBorder: true,
                  }
                : null,
            {
                icon: <RouteIcon fillColor={StyleGuideColorsEnum.gray} />,
                name: t('spot-request-details.details.distance'),
                value: (
                    <UnitTypeCount
                        type={UnitTypeEnum.kilometersAbbreviation}
                        count={convertToKm(details?.priceOffer?.distance)}
                    />
                ),
                rightNode: details?.priceOffer?.teamDrive ? <TeamDrivePill /> : null,
                isBoldValue: true,
                testSelector: 'distance',
                hasBottomBorder: true,
            },
            {
                icon: (
                    <TrailerIcon
                        size={DEFAULT_ICON_SIZE}
                        strokeColor={StyleGuideColorsEnum.gray}
                        fillColor={StyleGuideColorsEnum.light}
                    />
                ),
                name: t('spot-request-details.details.trailer-type'),
                value: <SimpleTrailerTypeFormatter trailerDictType={details?.trailerInfo} />,
                isBoldValue: true,
                testSelector: 'trailer',
                hasBottomBorder: true,
            },
            {
                icon: <EmissionIcon strokeColor={StyleGuideColorsEnum.gray} />,
                name: t('spot-request-details.details.emission'),
                value: <EmissionClassFormatter emissionClass={details?.emissionClass} />,
                isBoldValue: true,
                testSelector: 'emission',
            },
        ].filter(isNonNil);
    }, [t, details]);

    const bids = React.useMemo((): SpotBidT[] => {
        return details?.bids || [];
    }, [details]);

    const additionalServices = useMemo((): ShipperPriceOfferInfoTablePropsT['additionalServices'] => {
        if (!details?.additionalServiceCosts) {
            return [];
        }

        return details.additionalServiceCosts.reduce<
            NonNullable<ShipperPriceOfferInfoTablePropsT['additionalServices']>
        >((acc, additionalServiceCost) => {
            if (additionalServiceCost.type && !isNil(additionalServiceCost.cost)) {
                acc.push({
                    enum: additionalServiceCost.type,
                    cost: additionalServiceCost.cost,
                });
            }

            return acc;
        }, []);
    }, [details]);

    const firstShipment = details?.shipments?.[0] || null;
    const commentTableRows = useMemo(() => {
        return [
            {
                icon: <CommentIcon fillColor={StyleGuideColorsEnum.light} strokeColor={StyleGuideColorsEnum.gray} />,
                name: t('spot-request-details.details.comments'),
                value: firstShipment?.description,
                isBoldValue: true,
                testSelector: 'comments',
                emptyValue: t('common:info-table.placeholders.empty'),
            },
        ];
    }, [firstShipment]);

    const renderStop = (stop: ApiSpotStopT, index: number) => {
        const isDriveThrough = stop?.type === StopTypeEnum.driveThrough;
        const rows: Array<InfoTableRowT> = [
            {
                icon: (
                    <NumberIcon
                        number={index + 1}
                        fillColor={isDriveThrough ? StyleGuideColorsEnum.gray : StyleGuideColorsEnum.charcoal}
                    />
                ),
                name: t('spot-request-details.details.waypoint', {
                    number: index + 1,
                }),
                value: <LocationLabel format="s1_s2_zip_city_country" location={stop?.address} />,
                emptyValue: t('common:info-table.placeholders.not-specified'),
                isBoldValue: true,
                rows: [
                    ...(isDriveThrough
                        ? []
                        : [
                              {
                                  icon: null,
                                  name: t('spot-request-details.details.time-slots'),
                                  value: formatTimeInterval(stop?.timeSlotFrom, stop?.timeSlotTo),
                                  emptyValue: t('common:info-table.placeholders.not-specified'),
                                  isBoldValue: true,
                                  testSelector: 'date-time',
                                  hasBottomBorder: true,
                              },
                              {
                                  icon: null,
                                  name: t('spot-request-details.details.company-name'),
                                  value: stop?.contact?.companyName,
                                  emptyValue: t('common:info-table.placeholders.not-specified'),
                                  isBoldValue: true,
                                  testSelector: 'contact-company-name',
                                  hasBottomBorder: true,
                              },
                              {
                                  icon: null,
                                  name: t('spot-request-details.details.contact-name'),
                                  value: stop?.contact?.fullName,
                                  emptyValue: t('common:info-table.placeholders.not-specified'),
                                  isBoldValue: true,
                                  testSelector: 'contact-name',
                                  hasBottomBorder: true,
                              },
                              {
                                  icon: null,
                                  name: t('spot-request-details.details.contact-phone'),
                                  value: stop?.contact?.phone,
                                  emptyValue: t('common:info-table.placeholders.not-specified'),
                                  isBoldValue: true,
                                  testSelector: 'contact-phone',
                                  hasBottomBorder: true,
                              },
                              {
                                  icon: null,
                                  name: t('spot-request-details.details.contact-email'),
                                  value: stop?.contact?.email,
                                  emptyValue: t('common:info-table.placeholders.not-specified'),
                                  isBoldValue: true,
                                  testSelector: 'contact-email',
                                  hasBottomBorder: true,
                              },
                              {
                                  icon: null,
                                  name: t('spot-request-details.details.comments'),
                                  value: stop?.contact?.comment,
                                  emptyValue: t('common:info-table.placeholders.not-specified'),
                                  isBoldValue: true,
                                  testSelector: 'contact-comment',
                              },
                          ]),
                ],
            },
        ];

        return (
            <InfoTable
                key={index}
                shouldRenderIcons
                className={cx('table')}
                rows={rows}
                testSelector={`waypoint-${index}`}
            />
        );
    };

    const isShowStatus =
        details?.status !== SpotRequestStatusEnum.canceled && details?.status !== SpotRequestStatusEnum.assigned;

    const stops = details?.stops || [];
    const firstStop = stops[0] || null;
    const lastStop = stops[stops.length - 1] || null;

    return (
        <>
            <div className={cx('container')}>
                <SpotRequestDetailsTabs
                    className={cx('tabs')}
                    isShowStatus={isShowStatus}
                    details={details}
                    selectedTab={selectedTab}
                    onSelectTab={setSelectedTab}
                />
                {selectedTab === SpotDetailsTabEnum.details && (
                    <>
                        <OrderRouteLocations
                            className={cx('route')}
                            origin={firstStop?.address}
                            pickupDockingHoursFrom={firstStop?.timeSlotFrom}
                            pickupDockingHoursTo={firstStop?.timeSlotTo}
                            destination={lastStop?.address}
                            dropOffDockingHoursFrom={lastStop?.timeSlotFrom}
                            dropOffDockingHoursTo={lastStop?.timeSlotTo}
                        />
                        <ExcludedCountries
                            className={cx('excluded-countries')}
                            titleNode={t('spot-request-details.excluded-countries.title')}
                            countryCodes={details?.prohibitedCountries || []}
                            tooltipNode={t('spot-request-details.excluded-countries.tooltip')}
                        />
                        <InfoTable
                            shouldRenderIcons
                            className={cx('table')}
                            rows={entriesInfoTableRows}
                            testSelector="entries-details"
                        />
                        <InfoTable
                            shouldRenderIcons
                            className={cx('table')}
                            rows={entriesInfoTableRows2}
                            testSelector="entries-details-2"
                        />
                        <InfoTable
                            shouldRenderIcons
                            className={cx('table')}
                            rows={spotInfoTableRows}
                            testSelector="entries-details"
                        />
                        <InfoTable
                            shouldRenderIcons
                            className={cx('table')}
                            rows={commentTableRows}
                            testSelector="entries-details"
                        />
                    </>
                )}
                <ShipperPriceOfferInfoTable
                    className={cx('table')}
                    totalPriceLabel={t('spot-request-details.details.shipper-prices')}
                    totalPriceTooltip={t('spot-request-details.placeholders.shipper-prices')}
                    hasContract={details?.hasShipperContract}
                    totalPrice={details?.priceOffer?.totalPrice}
                    teamDriveCost={details?.priceOffer?.teamDriveCost}
                    lineHaulCost={details?.priceOffer?.lineHaul}
                    tollCost={details?.priceOffer?.tollCost}
                    co2={details?.priceOffer?.co2}
                    tollByRoadType={details?.priceOffer?.tollByRoadType}
                    tranziitServiceFee={details?.priceOffer?.serviceFee}
                    urgentOverprice={details?.priceOffer?.urgentOverprice}
                    layoverCost={details?.priceOffer?.layoverCost}
                    layoverSeconds={details?.priceOffer?.layoverSeconds}
                    fuelCost={details?.priceOffer?.fuelCost}
                    fuelByCountry={details?.priceOffer?.fuelByCountry}
                    greenOverprice={details?.priceOffer?.greenOverprice}
                    additionalServicesCost={details?.priceOffer?.additionalServicesCost}
                    additionalServices={additionalServices}
                    shouldAlwaysRenderExpandTrigger
                />
                <div className={cx('separator')} />
                <CarrierCostPriceInfoTable
                    className={cx('table')}
                    totalPrice={details?.carrierEstimatedCost?.totalCost}
                    totalPriceLabel={t('spot-request-details.details.estimate-carrier-cost')}
                    totalPriceTooltip={t('spot-request-details.placeholders.estimate-carrier-cost')}
                    lineHaulCost={details?.carrierEstimatedCost?.lineHaul}
                    tollCost={details?.carrierEstimatedCost?.tollCost}
                    tollByRoadType={details?.carrierEstimatedCost?.tollByRoadType}
                    co2={details?.carrierEstimatedCost?.co2}
                    layoverCost={details?.carrierEstimatedCost?.layoverCost}
                    layoverSeconds={details?.carrierEstimatedCost?.layoverSeconds}
                    fuelCost={details?.carrierEstimatedCost?.fuelCost}
                    fuelByCountry={details?.carrierEstimatedCost?.fuelByCountry}
                    teamDriveCost={details?.carrierEstimatedCost?.teamDriveCost}
                    otherCosts={details?.carrierEstimatedCost?.otherCosts}
                    testSelector="estimate-carrier-cost"
                    shouldAlwaysRenderExpandTrigger
                />
                <div className={cx('separator')} />
                <CarrierCostPriceInfoTable
                    className={cx('table')}
                    totalPrice={details?.carrierCost?.totalCost}
                    totalPriceLabel={t('spot-request-details.details.carrier-cost')}
                    totalPriceTooltip={t('spot-request-details.placeholders.carrier-cost')}
                    lineHaulCost={details?.carrierCost?.lineHaul}
                    tollCost={details?.carrierCost?.tollCost}
                    tollByRoadType={details?.carrierCost?.tollByRoadType}
                    co2={details?.carrierCost?.co2}
                    layoverCost={details?.carrierCost?.layoverCost}
                    layoverSeconds={details?.carrierCost?.layoverSeconds}
                    fuelCost={details?.carrierCost?.fuelCost}
                    fuelByCountry={details?.carrierCost?.fuelByCountry}
                    teamDriveCost={details?.carrierCost?.teamDriveCost}
                    otherCosts={details?.carrierCost?.otherCosts}
                    testSelector="carrier-cost"
                    shouldAlwaysRenderExpandTrigger
                />
                {selectedTab === SpotDetailsTabEnum.details && (
                    <>
                        <InfoTable
                            className={cx('table')}
                            shouldRenderIcons
                            rows={shipmentInfoTableRows}
                            testSelector="shipment-details"
                        />
                        {!!stops?.length && (
                            <Card
                                className={cx('waypoint-card')}
                                titleNode={t('spot-request-details.cards.route')}
                                rightNode={
                                    <PillLabel theme={PillLabelThemeEnum.slate} isSymmetrical>
                                        {t('spot-request-details.waypoint-counts', {
                                            number: stops.length,
                                        })}
                                    </PillLabel>
                                }
                                hasHeaderBottomBorder
                            >
                                {stops?.map((stop, index) => {
                                    return renderStop(stop, index);
                                })}
                            </Card>
                        )}
                        {details?.shipments?.map((shipment, index) => {
                            return (
                                <ShipmentDetailsCard
                                    key={`shipment-details-card-${index}`}
                                    className={cx('shipment-card')}
                                    isReeferTrailer={!!details?.trailerInfo?.reefer}
                                    shipmentNumber={index + 1}
                                    shipment={shipment}
                                    pickUpStop={stopById?.[shipment?.pickupPointId] || null}
                                    dropOffStop={stopById?.[shipment?.dropOffPointId] || null}
                                    keyboardShortcut={String(index + 1)}
                                />
                            );
                        })}
                    </>
                )}
                {selectedTab === SpotDetailsTabEnum.bids && (
                    <>
                        <Bids
                            className={cx('bids')}
                            bids={bids}
                            isDisabledSelectBid={isDisabledSelectBid}
                            selectedBidId={selectedBidId}
                            onSelectBid={onSelectBid}
                            estimateSpotRequestPrice={details?.carrierEstimatedCost?.totalCost || null}
                            onOpenUserDetails={onOpenUserDetails}
                        />
                    </>
                )}
            </div>
        </>
    );
});

export default SpotRequestDetails;
