import * as React from 'react';

import classNames from 'classnames/bind';
import cs from 'classnames';
import styles from './AveragePriceOfferDetails.scss';

import { useTranslation } from 'react-i18next';
import { CurrencyEnum, UnitTypeEnum } from 'common/constants';
import RuleImpact from 'common/components/RuleImpact/RuleImpact';
import UnitTypeCount from 'common/components/units/UnitTypeCount/UnitTypeCount';
import Money from 'common/components/Money/Money';
import PriceDetailsHeader from 'common/layouts/NewOrderPage/OfferDetails/PriceDetailsHeader/PriceDetailsHeader';
import { ApiPriceOfferT } from 'common/store/shipper-contract-lane-creation/types';
import { M_IN_KM } from 'common/utils/distance';
import { BrokerSidebarsTypeEnum } from 'broker-admin/layouts/SideBars/constants';
import { useOpenLeftSidebar } from 'broker-admin/layouts/SideBars/hooks';
import Link, { LinkThemeEnum } from 'common/components/Link/Link';
import ClickInterceptorLabel from 'common/components/ClickInterceptorLabel/ClickInterceptorLabel';
import PriceDetails, { PriceDetailT } from 'design-system/components/PriceDetails/PriceDetails';
import { useCommonPriceDetails } from 'common/components/PriceDetails/hook';

const cx = classNames.bind(styles);

type PropsT = {
    className?: string;
    priceOffer?: ApiPriceOfferT | null;
};

const AveragePriceOfferDetails: React.FC<PropsT> = React.memo((props) => {
    const { className, priceOffer } = props;

    const { t } = useTranslation();

    const commonPriceDetails = useCommonPriceDetails();

    const costOfOperationPrices: Array<PriceDetailT | null> = React.useMemo(() => {
        return [
            {
                ...commonPriceDetails.lineHaul,
                price: priceOffer?.lineHaul,
            },
            {
                ...commonPriceDetails.getRoadTaxes(priceOffer?.co2),
                price: priceOffer?.tollCost,
            },
            {
                ...commonPriceDetails.greenSurcharge,
                price: priceOffer?.greenOverprice,
            },
            {
                ...commonPriceDetails.fuelCost,
                price: priceOffer?.fuelCost,
            },
        ];
    }, [priceOffer]);

    const otherPrices: Array<PriceDetailT> = React.useMemo(() => {
        return [
            {
                ...commonPriceDetails.tranziitServiceFee,
                price: priceOffer?.serviceFee,
            },
        ];
    }, [priceOffer]);

    const openLeftSidebar = useOpenLeftSidebar();

    const appliedRulePrices: Array<PriceDetailT> = React.useMemo(() => {
        if (!priceOffer?.brokerInfo?.appliedRules) {
            return [];
        }

        return priceOffer.brokerInfo.appliedRules.map((appliedRule) => {
            return {
                title: (
                    <ClickInterceptorLabel>
                        <Link
                            onClick={() => {
                                openLeftSidebar({
                                    type: BrokerSidebarsTypeEnum.editPriceSettingsRuleSidebar,
                                    editableRuleId: String(appliedRule.id),
                                });
                            }}
                            theme={LinkThemeEnum.boldBrandDark}
                        >
                            {appliedRule?.name}
                        </Link>
                    </ClickInterceptorLabel>
                ),
                unit: <RuleImpact value={appliedRule.value} impactStrategy={appliedRule.valueType} />,
            };
        });
    }, [priceOffer]);

    const costOfOperationRate = priceOffer ? (priceOffer.costOfOperation || 0) / (priceOffer.distance / M_IN_KM) : null;

    return (
        <div className={cs(cx('inner'), className)}>
            <PriceDetailsHeader
                className={cx('price-details__header')}
                title={t('shipper-lane-creation.average-price-offer-details.block.cost-of-operation')}
                rightNode={
                    priceOffer ? (
                        <span className={cx('rates')}>
                            <Money currency={CurrencyEnum.EUR} amount={priceOffer?.costOfOperation} />
                            {' ('}
                            <UnitTypeCount
                                types={[UnitTypeEnum.euroAbbreviation, UnitTypeEnum.kilometersAbbreviation]}
                                count={costOfOperationRate}
                            />
                            )
                        </span>
                    ) : null
                }
            />
            <div className={cx('price-details__list')}>
                <PriceDetails list={costOfOperationPrices} />
            </div>
            <PriceDetailsHeader title={t('shipper-lane-creation.average-price-offer-details.block.other-costs')} />
            <div className={cx('price-details__list')}>
                <PriceDetails list={otherPrices} />
            </div>
            {priceOffer ? (
                <>
                    <PriceDetailsHeader
                        title={t('shipper-lane-creation.average-price-offer-details.block.applied-rules', {
                            postProcess: 'interval',
                            count: appliedRulePrices?.length,
                        })}
                    />
                    <div
                        className={cx('price-details__list', {
                            'price-details__list--isLast': true,
                        })}
                    >
                        <PriceDetails list={appliedRulePrices} />
                    </div>
                </>
            ) : (
                <PriceDetailsHeader
                    title={t('shipper-lane-creation.average-price-offer-details.block.applied-rules-loading')}
                />
            )}
        </div>
    );
});

export default AveragePriceOfferDetails;
