import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useCommonPriceDetails } from 'common/components/PriceDetails/hook';
import PriceDetails, { PriceDetailT } from 'design-system/components/PriceDetails/PriceDetails';
import EuroSymbolIcon from 'common/icons/EuroSymbolIcon';
import { StyleGuideColorsEnum } from 'common/constants';
import { isNonNil } from 'common/utils';
import { SelfCostPredictionT } from 'broker-admin/store/dispatch-assigment/models';
import isNumber from 'lodash/isNumber';

type CostsT = SelfCostPredictionT['calculatedTrailerCost'] | SelfCostPredictionT['trailerNextTourOldCostDetails'];

type PropsT = {
    className?: string;
    label: React.ReactNode;
    isHideFuelPrice?: boolean;
    prevCosts?: CostsT;
    costs: CostsT;
};

const getDiff = (price: number | null | undefined, notActualPrice: number | null | undefined): number | null => {
    if (!isNumber(price) || !isNumber(notActualPrice)) {
        return null;
    }

    return price - notActualPrice;
};

const CUSTOM_PRICE_DIFF_NEGATIVE_COLOR = StyleGuideColorsEnum.orange;

const ReceiptPriceDetails: React.FC<PropsT> = React.memo((props) => {
    const { className, label, prevCosts, costs, isHideFuelPrice } = props;

    const { t } = useTranslation();

    const commonPriceDetails = useCommonPriceDetails();

    const list: Array<PriceDetailT | null> = [
        {
            iconNode: <EuroSymbolIcon strokeColor={StyleGuideColorsEnum.gray} />,
            title: label,
            price: costs?.totalCost,
            priceDiff: getDiff(costs?.totalCost, prevCosts?.totalCost),
            notActualPrice: prevCosts?.totalCost,
            customPriceDiffNegativeColor: CUSTOM_PRICE_DIFF_NEGATIVE_COLOR,
            placeholder: t('common:info-table.placeholders.carrier-not-assigned'),
            initialOpen: true,
            list: [
                {
                    ...commonPriceDetails.lineHaul,
                    price: costs?.lineHaul,
                    priceDiff: getDiff(costs?.lineHaul, prevCosts?.lineHaul),
                    notActualPrice: prevCosts?.lineHaul,
                    customPriceDiffNegativeColor: CUSTOM_PRICE_DIFF_NEGATIVE_COLOR,
                },
                isNumber(costs?.teamDriveCost)
                    ? {
                          ...commonPriceDetails.teamDrive,
                          price: costs?.teamDriveCost,
                          priceDiff: getDiff(costs?.teamDriveCost, prevCosts?.teamDriveCost),
                          customPriceDiffNegativeColor: CUSTOM_PRICE_DIFF_NEGATIVE_COLOR,
                          notActualPrice: prevCosts?.teamDriveCost,
                      }
                    : null,
                {
                    ...commonPriceDetails.getRoadTaxes(costs?.co2),
                    price: costs?.tollCost,
                    priceDiff: getDiff(costs?.tollCost, prevCosts?.tollCost),
                    customPriceDiffNegativeColor: CUSTOM_PRICE_DIFF_NEGATIVE_COLOR,
                    notActualPrice: prevCosts?.tollCost,
                    list: commonPriceDetails.getRoadTaxesList(costs?.tollByRoadType),
                },
                !isHideFuelPrice
                    ? {
                          ...commonPriceDetails.fuelCost,
                          price: costs?.fuelCost,
                          priceDiff: getDiff(costs?.fuelCost, prevCosts?.fuelCost),
                          customPriceDiffNegativeColor: CUSTOM_PRICE_DIFF_NEGATIVE_COLOR,
                          notActualPrice: prevCosts?.fuelCost,
                          list: commonPriceDetails.getFuelCostsByCountryList(costs?.fuelByCountry),
                      }
                    : null,
                {
                    ...commonPriceDetails.getLayoverCost(costs?.layoverSeconds),
                    price: costs?.layoverCost,
                    priceDiff: getDiff(costs?.layoverCost, prevCosts?.layoverCost),
                    customPriceDiffNegativeColor: CUSTOM_PRICE_DIFF_NEGATIVE_COLOR,
                    notActualPrice: prevCosts?.layoverCost,
                },
                isNumber(costs?.otherCosts)
                    ? {
                          ...commonPriceDetails.otherCosts,
                          price: costs?.otherCosts,
                          priceDiff: getDiff(costs?.otherCosts, prevCosts?.otherCosts),
                          customPriceDiffNegativeColor: CUSTOM_PRICE_DIFF_NEGATIVE_COLOR,
                          notActualPrice: prevCosts?.otherCosts,
                      }
                    : null,
            ].filter(isNonNil),
        },
    ].filter(isNonNil);

    if (!costs) {
        return null;
    }

    return <PriceDetails className={className} list={list} />;
});

export default ReceiptPriceDetails;
