import React, { useContext, useEffect, useMemo } from 'react';
import classNames from 'classnames/bind';

import styles from './AssignmentForm.scss';
import { useTranslation } from 'react-i18next';
import Button, { ButtonThemeEnum } from 'common/components/Button/Button';
import { DropdownOverlayPositionEnum } from 'design-system/components/dropdowns/constants';
import AssignOptionLabel from './AssignOptionLabel/AssignOptionLabel';
import TruckIcon, { TruckIconProps } from 'common/icons/TruckIcon';
import TrailerIcon, { TrailerIconProps } from 'common/icons/TrailerIcon';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectAssignmentRequestStatus,
    selectSelfCostPrediction,
    selectSelfCostPredictionRequestStatus,
    selectTrailerAssignmentInfoById,
    selectTrailerSuggestIds,
    selectTrailerSuggestRequestStatus,
    selectTruckAssignmentInfoById,
    selectTruckSuggestIds,
    selectTruckSuggestRequestStatus,
    selectUserSelection,
    selectVehicleLinks,
} from 'broker-admin/store/dispatch-assigment/selectors';
import {
    assign,
    changeUserSelection,
    fetchAssignDetails,
    findTrailer,
    findTruck,
    initUserSelection,
    setInitAssetsInfo,
} from 'broker-admin/store/dispatch-assigment/actions';
import { getDateFromISO, MS_IN_MIN } from 'common/utils/time';
import {
    selectCarriersUtilizationByHash,
    selectFetchRequestStatus as selectCarriersUtilizationRequestStatus,
} from 'common/store/carriers-utilization/selectors';
import { getHash } from 'common/store/carriers-utilization/utils';
import { FieldsEnum, FormValuesT } from './constants';
import validateForm from './validate-form';
import { useFormik } from 'formik';
import FormikField from 'common/components/forms/FormikField/FormikField';
import { DispatchDetailsT } from 'broker-admin/store/dispatch-details/models';
import asyncValidations from './async-validations';
import isEmpty from 'lodash/isEmpty';
import { PointToDropT, UserSelectionT } from 'broker-admin/store/dispatch-assigment/models';
import GeoSuggest from 'common/components/GeoSuggest/GeoSuggest';
import isEqual from 'lodash/isEqual';
import ButtonTimer from 'common/components/ButtonTimer/ButtonTimer';
import { logWarning } from 'common/utils/logger';
import { CommonSidebarsTypeEnum } from 'common/layouts/SideBars/models';
import { useOpenLeftSidebar } from 'broker-admin/layouts/SideBars/hooks';
import isNumber from 'lodash/isNumber';
import useOnlyChangesEffect from 'common/utils/hooks/useOnlyChangesEffect';
import DropdownSearchInput, {
    IconMetaT,
} from 'design-system/components/dropdowns/DropdownSearchInput/DropdownSearchInput';
import AssignTriggerLabel from './AssignTriggerLabel/AssignTriggerLabel';
import { AssetAssignmentInfoT } from 'common/utils/assignment';
import AssetLinkIcon, { AssetLinkIconProps } from 'common/icons/AssetLinkIcon';
import useAsyncFormErrors from 'common/utils/hooks/useAsyncFormErrors';
import { renderTrailerDropOffIcon, renderTruckDropOffIcon } from './icons';
import TransparentTrigger, { ReflectionThemeEnum } from 'common/components/TransparentTrigger/TransparentTrigger';
import mapValues from 'lodash/mapValues';
import { findTourById } from 'broker-admin/store/dispatch-details/utils/find-tour-by-id';
import ScrollToFirstError from 'common/components/ScrollToFirstError/ScrollToFirstError';
import FooterSideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/FooterSideBarLayout/FooterSideBarLayout';
import TourTypeTabs from './TourTypeTabs/TourTypeTabs';
import { TourTypeTabsEnum } from 'broker-admin/layouts/DispatchesPage/DispatchAssignmentPage/AssignmentForm/TourTypeTabs/constants';
import LongDeadheadConfirmation from './LongDeadheadConfirmation/LongDeadheadConfirmation';
import useCloseModalDialogAfterRequest from 'common/utils/hooks/useCloseModalDialogAfterRequest';

import useModalDialog from 'common/utils/hooks/useModalDialog';

import { LongDeadheadConfirmationDataT } from './LongDeadheadConfirmation/models';
import { LONG_DEADHEAD_PERCENT } from 'broker-admin/layouts/DispatchesPage/DispatchAssignmentPage/constants';
import InfoTable, { InfoTableRowT } from 'design-system/components/InfoTable/InfoTable';
import { DEFAULT_ICON_SIZE, StyleGuideColorsEnum } from 'common/constants';
import AssetLabelFormatter from 'design-system/components/InfoTable/formatters/AssetLabelFormatter/AssetLabelFormatter';
import ColoredStatusLabel from 'common/components/ColoredStatusLabel/ColoredStatusLabel';
import FlagIcon from 'common/icons/FlagIcon/FlagIcon';
import ReceiptPriceDetails from 'broker-admin/layouts/DispatchesPage/DispatchAssignmentPage/AssignmentForm/ReceiptPriceDetails/ReceiptPriceDetails';
import MileageInfoTable from './MileageInfoTable/MileageInfoTable';
import AssetTypeTabs from 'broker-admin/layouts/DispatchesPage/DispatchAssignmentPage/AssignmentForm/AssetTypeTabs/AssetTypeTabs';
import { AssetTypeTabsEnum } from 'broker-admin/layouts/DispatchesPage/DispatchAssignmentPage/AssignmentForm/AssetTypeTabs/constants';
import ControlLoaderLabel from 'common/components/ControlLoaderLabel/ControlLoaderLabel';
import { findActualTransportOrders } from 'broker-admin/store/dispatch-details/utils/find-actual-transport-order';
import { CancelTruckTransportOrderConfirmationDataT } from 'broker-admin/layouts/DispatchesPage/DispatchAssignmentPage/AssignmentForm/CancelTruckTransportOrderConfirmation/models';
import { CancelTrailerTransportOrderConfirmationDataT } from 'broker-admin/layouts/DispatchesPage/DispatchAssignmentPage/AssignmentForm/CancelTrailerTransportOrderConfirmation/models';
import { updateEntityStatus } from 'common/store/state-machine/slice';
import { ApiTransportOrderStatusT, PartnerTypeEnum, TransportOrderStatusEnum } from 'common/utils/api/models';
import { selectStateMachineItemState } from 'common/store/state-machine/selectors';
import { StateMachineEntityDescriptorT } from 'common/store/state-machine/models';
import CancelTrailerTransportOrderConfirmation from 'broker-admin/layouts/DispatchesPage/DispatchAssignmentPage/AssignmentForm/CancelTrailerTransportOrderConfirmation/CancelTrailerTransportOrderConfirmation';
import CancelTruckTransportOrderConfirmation from 'broker-admin/layouts/DispatchesPage/DispatchAssignmentPage/AssignmentForm/CancelTruckTransportOrderConfirmation/CancelTruckTransportOrderConfirmation';
import Link, { LinkThemeEnum } from 'common/components/Link/Link';
import { createInitWaypointTruckDropPoint } from 'broker-admin/layouts/DispatchesPage/DispatchAssignmentPage/hooks/createInitWaypointTruckDropPoint';
import { createInitWaypointTrailerDropPoint } from 'broker-admin/layouts/DispatchesPage/DispatchAssignmentPage/hooks/createInitWaypointTrailerDropPoint';
import { createInitPayloadDropPoint } from 'broker-admin/layouts/DispatchesPage/DispatchAssignmentPage/hooks/createInitPayloadDropPoint';
import NumberIcon from 'common/icons/NumberIcon';
import AssetsSelectionContext from 'broker-admin/layouts/DispatchesPage/DispatchAssignmentPage/contexts/assets-selection-context';
import { fetchCountrySettingsDict } from 'common/store/country-settings-dict/actions';
import { selectCountrySettingsByCountryCode } from 'common/store/country-settings-dict/selectors';
import { SyncAssignmentFormMapStateContext } from '../contexts/sync-map-state';
import { urlFactory } from 'broker-admin/utils/urls';
import { OnMapClickCallbackT } from '../hooks/usySyncMapState';

const cx = classNames.bind(styles);

type PropsT = {
    dispatchId: DispatchIdT | null;
    dispatchDetails: DispatchDetailsT | null;
    tourId: TourIdT | null;
    onCancel: () => void;
};

type TrailerOptionT = AssetAssignmentInfoT & {
    linkedAssetId: AssetIdT | null;
    carrierUtilizationPercent: number | null;
    isCarrierUtilizationLoading: boolean;
};

type TruckOptionT = AssetAssignmentInfoT & {
    linkedAssetId: AssetIdT | null;
    carrierUtilizationPercent: number | null;
    isCarrierUtilizationLoading: boolean;
};

const convertLocationToPoint = (location: LocationT | null): PointToDropT | null => {
    if (!location || !location.point) {
        return null;
    }

    return {
        address: location.address || `${location.point?.lat},${location.point?.lng}`,
        addressComponents: location.addressComponents,
        point: location.point,
    };
};

const convertPointToLocation = (pointToDrop: PointToDropT | null): LocationT | null => {
    if (!pointToDrop || !pointToDrop?.point) {
        return null;
    }

    return {
        point: pointToDrop?.point || null,
        address: pointToDrop?.address || null,
        addressComponents: pointToDrop?.addressComponents || null,
        utcOffsetMinutes: null,
    };
};

const checkIsSameLocation = (
    locationA: LocationT | PointToDropT | null,
    locationB: LocationT | PointToDropT | null,
): boolean => {
    return isEqual(locationA?.point, locationB?.point);
};

const getAssetUtilizationColor = (percent: number | null | undefined) => {
    if (isNumber(percent) && percent > 90) {
        return StyleGuideColorsEnum.brandDark;
    }

    if (isNumber(percent) && percent > 50) {
        return StyleGuideColorsEnum.orange;
    }

    return StyleGuideColorsEnum.tomatoRed;
};

const getFleetUtilizationColor = (percent: number | null | undefined) => {
    if (isNumber(percent) && percent > 90) {
        return StyleGuideColorsEnum.brandDark;
    }

    if (isNumber(percent) && percent > 50) {
        return StyleGuideColorsEnum.orange;
    }

    return StyleGuideColorsEnum.tomatoRed;
};

const AssignmentForm: React.FC<PropsT> = (props) => {
    const { onCancel, dispatchId, dispatchDetails, tourId } = props;

    const { t } = useTranslation();

    const openLeftSidebar = useOpenLeftSidebar();

    const syncMapStateContext = useContext(SyncAssignmentFormMapStateContext);

    const [selectedAssetType, setSelectedAssetType] = React.useState<AssetTypeTabsEnum | null>(null);
    const [selectedTourTypeTab, onSelectTourTypeTab] = React.useState<TourTypeTabsEnum>(TourTypeTabsEnum.current);

    const assetsSelectionContext = useContext(AssetsSelectionContext);

    const firstWaypoint = findTourById(dispatchDetails, tourId)?.waypoints?.[0] || null;
    const firstWaypointDateTimeFrom =
        firstWaypoint?.correctedDateTimeFrom || firstWaypoint?.originalDateTimeFrom || null;
    const pickupDate = getDateFromISO(firstWaypointDateTimeFrom);

    const dispatch = useDispatch();

    const userSelection = useSelector(selectUserSelection);

    const assignmentRequestStatus = useSelector(selectAssignmentRequestStatus);

    const selfCostPrediction = useSelector(selectSelfCostPrediction);
    const selfCostPredictionRequestStatus = useSelector(selectSelfCostPredictionRequestStatus);

    const vehicleLinks = useSelector(selectVehicleLinks);

    const truckSuggestRequestStatus = useSelector(selectTruckSuggestRequestStatus);
    const truckIds = useSelector(selectTruckSuggestIds);
    const truckById = useSelector(selectTruckAssignmentInfoById);

    const trailerSuggestRequestStatus = useSelector(selectTrailerSuggestRequestStatus);
    const trailerIds = useSelector(selectTrailerSuggestIds);
    const trailerById = useSelector(selectTrailerAssignmentInfoById);

    const carriersUtilizationFetchRequestStatus = useSelector(selectCarriersUtilizationRequestStatus);
    const carriersUtilizationByHash = useSelector(selectCarriersUtilizationByHash);

    const countriesSettingsByCode = useSelector(selectCountrySettingsByCountryCode);
    React.useEffect(() => {
        dispatch(fetchCountrySettingsDict());
    }, []);

    const validate = React.useMemo(() => {
        return (values: FormValuesT) =>
            validateForm(t, values, {
                trailerById,
                truckById,
                countriesSettingsByCode,
            });
    }, [t, trailerById, truckById, countriesSettingsByCode]);

    const initPayloadDropPoint = useMemo(() => {
        return createInitPayloadDropPoint(dispatchDetails, tourId);
    }, [dispatchDetails, tourId]);

    const initWaypointTruckDropPoint = useMemo(() => {
        return createInitWaypointTruckDropPoint(dispatchDetails, tourId);
    }, [dispatchDetails, tourId]);

    const initWaypointTrailerDropPoint = useMemo(() => {
        return createInitWaypointTrailerDropPoint(dispatchDetails, tourId);
    }, [dispatchDetails, tourId]);

    const initTruckDropPoint = initWaypointTruckDropPoint || initPayloadDropPoint || null;
    const initTrailerDropPoint = initWaypointTrailerDropPoint || initPayloadDropPoint || null;

    const { truckTransportOrder: initTruckTransportOrder, trailerTransportOrder: initTrailerTransportOrder } =
        useMemo(() => {
            const tour = findTourById(dispatchDetails, tourId);
            return findActualTransportOrders(tour?.transportationOrders);
        }, [dispatchDetails, tourId]);

    const initTruck = initTruckTransportOrder?.truck || null;
    const initTrailer = initTrailerTransportOrder?.trailer || null;

    const hasDispatchDetails = !!dispatchDetails;

    const [initialValues, initialErrors] = React.useMemo(() => {
        const values: FormValuesT = {
            [FieldsEnum.trailerId]: initTrailer?.id || null,
            [FieldsEnum.truckId]: initTruck?.id || null,
            [FieldsEnum.locationToDropTrailer]: initTrailerDropPoint?.location || null,
            [FieldsEnum.locationToDropTruck]: initTruckDropPoint?.location || null,
        };

        const errors = validateForm(t, values, { trailerById, truckById, countriesSettingsByCode });

        return [values, errors];
    }, [hasDispatchDetails]);

    useEffect(() => {
        dispatch(
            setInitAssetsInfo(
                {
                    truck: {
                        carrierId: initTruck?.carrier?.id,
                        carrierName: initTruck?.carrier?.name,
                        id: initTruck?.id,
                        plateNumber: initTruck?.plateNumber,
                        model: initTruck?.dictTruckInfo?.model,
                    },
                },
                {
                    trailer: {
                        carrierId: initTrailer?.carrier?.id,
                        carrierName: initTrailer?.carrier?.name,
                        id: initTrailer?.id,
                        plateNumber: initTrailer?.plateNumber,
                        model: initTrailer?.dictTrailerInfo?.model,
                    },
                },
            ),
        );
    }, [initTruck, initTrailer]);

    useEffect(() => {
        dispatch(
            initUserSelection(dispatchId, tourId, {
                truckId: initialValues[FieldsEnum.truckId],
                pointToDropTruck: convertLocationToPoint(initialValues[FieldsEnum.locationToDropTruck]),
                trailerId: initialValues[FieldsEnum.trailerId],
                pointToDropTrailer: convertLocationToPoint(initialValues[FieldsEnum.locationToDropTrailer]),
            }),
        );
    }, [initialValues]);

    useEffect(() => {
        if (tourId && dispatchId) {
            dispatch(fetchAssignDetails(dispatchId, tourId));
        }
    }, [dispatchId, tourId]);

    const assignmentContextId = selfCostPrediction?.assignmentContextId || null;

    const cancelTruckTransportOrderConfirmation = useModalDialog<CancelTruckTransportOrderConfirmationDataT>();
    const cancelTruckTransportOrderDescriptor = React.useMemo((): StateMachineEntityDescriptorT | null => {
        if (!initTruckTransportOrder?.id) {
            return null;
        }

        return {
            entityType: 'TRANSPORTATION_ORDER',
            entityId: initTruckTransportOrder?.id,
        };
    }, [initTruckTransportOrder]);

    const handleConfirmCancelTruckTransportOrder = (data: CancelTruckTransportOrderConfirmationDataT) => {
        if (!cancelTruckTransportOrderDescriptor) {
            return;
        }

        dispatch(
            updateEntityStatus({
                update: {
                    entityType: 'TRANSPORTATION_ORDER',
                    entityId: data.transportOrderId,
                    status: TransportOrderStatusEnum.canceled,
                },
                descriptor: cancelTruckTransportOrderDescriptor,
            }),
        );
    };
    const { updateRequest: updateTruckTransportOrderStatusRequest } = useSelector(
        selectStateMachineItemState<ApiTransportOrderStatusT>(cancelTruckTransportOrderDescriptor),
    );
    useCloseModalDialogAfterRequest(updateTruckTransportOrderStatusRequest, [cancelTruckTransportOrderConfirmation]);

    const cancelTrailerTransportOrderConfirmation = useModalDialog<CancelTrailerTransportOrderConfirmationDataT>();
    const cancelTrailerTransportOrderDescriptor = React.useMemo((): StateMachineEntityDescriptorT | null => {
        if (!initTrailerTransportOrder?.id) {
            return null;
        }

        return {
            entityType: 'TRANSPORTATION_ORDER',
            entityId: initTrailerTransportOrder.id,
        };
    }, [initTrailerTransportOrder]);
    const handleConfirmCancelTrailerTransportOrder = (data: CancelTrailerTransportOrderConfirmationDataT) => {
        if (!cancelTrailerTransportOrderDescriptor) {
            return;
        }

        dispatch(
            updateEntityStatus({
                update: {
                    entityType: 'TRANSPORTATION_ORDER',
                    entityId: data.transportOrderId,
                    status: TransportOrderStatusEnum.canceled,
                },
                descriptor: cancelTrailerTransportOrderDescriptor,
            }),
        );
    };
    const { updateRequest: updateTrailerTransportOrderStatusRequest } = useSelector(
        selectStateMachineItemState<ApiTransportOrderStatusT>(cancelTrailerTransportOrderDescriptor),
    );
    useCloseModalDialogAfterRequest(updateTrailerTransportOrderStatusRequest, [
        cancelTrailerTransportOrderConfirmation,
    ]);

    const longDeadheadConfirmation = useModalDialog<LongDeadheadConfirmationDataT>();
    useCloseModalDialogAfterRequest(assignmentRequestStatus, [longDeadheadConfirmation]);

    const formik = useFormik<FormValuesT>({
        enableReinitialize: true,
        validateOnBlur: false,
        initialErrors,
        initialValues,
        validate,
        onSubmit: (values, formikHelpers): void => {
            if (!assignmentContextId) {
                logWarning(`Failed to assign, empty assignmentContextId`);
                return;
            }

            if (!dispatchId) {
                logWarning(`Failed to assign, empty dispatchId`);
                return;
            }

            if (!tourId) {
                logWarning(`Failed to assign, empty tourId`);
                return;
            }

            const totalDeadheadDistance = selfCostPrediction?.calculatedSumCost?.deadheadDistance?.total;
            const payloadDistance = selfCostPrediction?.calculatedSumCost?.payloadDistance?.total;
            const shouldConfirmLongDeadhead =
                isNumber(totalDeadheadDistance) && isNumber(payloadDistance)
                    ? totalDeadheadDistance / payloadDistance > LONG_DEADHEAD_PERCENT / 100
                    : false;
            if (shouldConfirmLongDeadhead) {
                longDeadheadConfirmation.setData({
                    dispatchId,
                    tourId,
                    assignmentContextId,
                });
            } else {
                dispatch(assign(dispatchId, tourId, assignmentContextId));

                formikHelpers.setTouched({});
            }
        },
    });

    const isDisabledTruckSuggest = initTruck?.id === formik.values[FieldsEnum.truckId];
    const isDisabledTrailerSuggest = initTrailer?.id === formik.values[FieldsEnum.trailerId];
    React.useEffect(() => {
        if (!assetsSelectionContext?.setValue) {
            return;
        }

        assetsSelectionContext.setValue({
            isBlockedSelectTruck: isDisabledTruckSuggest,
            isBlockedSelectTrailer: isDisabledTrailerSuggest,
        });
    }, [isDisabledTruckSuggest, isDisabledTrailerSuggest]);

    const asyncErrors = React.useMemo(() => {
        return asyncValidations(t, formik.values, {
            trailerById,
            truckById,
            selfCostPredictionRequestStatus,
        });
    }, [t, formik.values, trailerById, truckById, selfCostPredictionRequestStatus]);

    const { asyncFormErrors, resetAsyncFormErrors } = useAsyncFormErrors(asyncErrors);

    React.useEffect(() => {
        syncMapStateContext?.setHasDropTrailerPointError(
            !!formik.errors?.[FieldsEnum.locationToDropTrailer] || !!asyncErrors?.[FieldsEnum.locationToDropTrailer],
        );
        syncMapStateContext?.setHasDropTruckPointError(
            !!formik.errors?.[FieldsEnum.locationToDropTruck] || !!asyncErrors?.[FieldsEnum.locationToDropTruck],
        );
    }, [formik.errors, asyncErrors]);

    const selectedTruckId = formik.values[FieldsEnum.truckId];
    const selectedTrailerId = formik.values[FieldsEnum.trailerId];
    useOnlyChangesEffect(() => {
        const changes: Partial<FormValuesT> = {};
        const touched: Partial<Record<FieldsEnum, boolean>> = {};
        if (selectedTrailerId !== userSelection.trailerId) {
            changes[FieldsEnum.trailerId] = userSelection.trailerId;
            touched[FieldsEnum.trailerId] = true;
        }
        if (selectedTruckId !== userSelection.truckId) {
            changes[FieldsEnum.truckId] = userSelection.truckId;
            touched[FieldsEnum.truckId] = true;
        }

        if (!isEmpty(changes)) {
            formik.setValues((prevValues) => ({
                ...prevValues,
                ...changes,
            }));
        }

        if (!isEmpty(touched)) {
            formik.setTouched({
                ...formik.touched,
                ...touched,
            });
        }
    }, [userSelection.truckId, userSelection.trailerId]);

    const selectedPointToDropTruck = formik.values[FieldsEnum.locationToDropTruck];
    const selectedPointToDropTrailer = formik.values[FieldsEnum.locationToDropTrailer];
    const isSelectedSameLocations = useMemo(() => {
        return checkIsSameLocation(selectedPointToDropTruck, selectedPointToDropTrailer);
    }, [selectedPointToDropTrailer, selectedPointToDropTruck]);

    useOnlyChangesEffect(() => {
        const changes: Partial<FormValuesT> = {};
        const touched: Partial<Record<FieldsEnum, boolean>> = {};

        if (!checkIsSameLocation(formik.values[FieldsEnum.locationToDropTruck], userSelection.pointToDropTruck)) {
            changes[FieldsEnum.locationToDropTruck] = convertPointToLocation(userSelection.pointToDropTruck);
            touched[FieldsEnum.locationToDropTruck] = true;
        }

        if (!checkIsSameLocation(formik.values[FieldsEnum.locationToDropTrailer], userSelection.pointToDropTrailer)) {
            changes[FieldsEnum.locationToDropTrailer] = convertPointToLocation(userSelection.pointToDropTrailer);
            touched[FieldsEnum.locationToDropTrailer] = true;

            if (isSelectedSameLocations) {
                changes[FieldsEnum.locationToDropTruck] = convertPointToLocation(userSelection.pointToDropTrailer);
                touched[FieldsEnum.locationToDropTruck] = true;
            }
        }

        if (!isEmpty(changes)) {
            formik.setValues((prevValues) => ({
                ...prevValues,
                ...changes,
            }));
        }

        if (!isEmpty(touched)) {
            formik.setTouched({
                ...formik.touched,
                ...touched,
            });
        }
    }, [userSelection.pointToDropTruck, userSelection.pointToDropTrailer]);

    const { values } = formik;
    const onMapClickCallback: OnMapClickCallbackT = React.useCallback(
        (pointToDrop) => {
            const location = convertPointToLocation(pointToDrop);

            const formValueChanges: Partial<FormValuesT> = {};
            const userSelectionChanges: Partial<UserSelectionT> = {};

            if (!values[FieldsEnum.locationToDropTrailer]) {
                formValueChanges[FieldsEnum.locationToDropTrailer] = location;
                userSelectionChanges.pointToDropTrailer = convertLocationToPoint(location);
            }

            if (!values[FieldsEnum.locationToDropTruck]) {
                formValueChanges[FieldsEnum.locationToDropTruck] = location;
                userSelectionChanges.pointToDropTruck = convertLocationToPoint(location);
            }

            if (!isEmpty(formValueChanges)) {
                formik.setValues((formValues) => ({ ...formValues, ...formValueChanges }));
            }

            if (!isEmpty(userSelectionChanges)) {
                dispatch(changeUserSelection(dispatchId, tourId, userSelectionChanges));
            }
        },
        [dispatch, values, dispatchId, tourId],
    );

    React.useEffect(() => {
        syncMapStateContext?.setOnMapClickCallback({ callback: onMapClickCallback });
    }, [onMapClickCallback, syncMapStateContext?.setOnMapClickCallback]);

    const trailersOptions: TrailerOptionT[] = React.useMemo(() => {
        const ids = [...trailerIds];

        if (selectedTrailerId && !trailerIds.includes(selectedTrailerId)) {
            ids.unshift(selectedTrailerId);
        }

        const options = ids.reduce((result, id) => {
            const trailer = trailerById[id] || null;
            if (!trailer) {
                return result;
            }

            const carrierUtilizationHash = getHash(trailer.carrierId as string, pickupDate);

            const carrierUtilization = carriersUtilizationByHash[carrierUtilizationHash];

            result.push({
                ...trailer,
                linkedAssetId: vehicleLinks.byTrailerId[trailer?.id as TrailerIdT] || null,
                carrierUtilizationPercent: carrierUtilization ? carrierUtilization.utilizationPercents : null,
                isCarrierUtilizationLoading: carriersUtilizationFetchRequestStatus.loading,
            });

            return result;
        }, [] as TrailerOptionT[]);

        return options;
    }, [
        trailerById,
        trailerIds,
        selectedTrailerId,
        pickupDate,
        carriersUtilizationFetchRequestStatus,
        carriersUtilizationByHash,
    ]);

    const trucksOptions: TruckOptionT[] = React.useMemo(() => {
        const ids = [...truckIds];

        if (selectedTruckId && !truckIds.includes(selectedTruckId)) {
            ids.unshift(selectedTruckId);
        }

        const options = ids.reduce((options, id) => {
            const truck = truckById[id] || null;
            if (!truck) {
                return options;
            }

            const carrierUtilizationHash = getHash(truck.carrierId as string, pickupDate);

            const carrierUtilization = carriersUtilizationByHash[carrierUtilizationHash];

            options.push({
                ...truck,
                linkedAssetId: vehicleLinks.byTruckId[truck?.id as TrailerIdT] || null,
                carrierUtilizationPercent: carrierUtilization ? carrierUtilization.utilizationPercents : null,
                isCarrierUtilizationLoading: carriersUtilizationFetchRequestStatus.loading,
            });

            return options;
        }, [] as TruckOptionT[]);

        return options;
    }, [
        truckById,
        truckIds,
        selectedTruckId,
        pickupDate,
        carriersUtilizationFetchRequestStatus,
        carriersUtilizationByHash,
    ]);

    const getOptionValue = (option: TruckOptionT | TrailerOptionT) => option?.id;

    const handleSelectLinkByTruck = (truckId: TruckIdT) => {
        const linkedTrailerId = vehicleLinks.byTruckId[truckId] || null;

        formik.setValues((values) => {
            return {
                ...values,
                [FieldsEnum.truckId]: truckId,
                [FieldsEnum.trailerId]: linkedTrailerId || values[FieldsEnum.trailerId],
            };
        });

        // Set meta => touched true
        setTimeout(() => {
            formik.setTouched({
                ...formik.touched,
                [FieldsEnum.truckId]: true,
                [FieldsEnum.trailerId]: true,
            });
        }, 0);

        const changes: Partial<UserSelectionT> = {
            trailerId: linkedTrailerId,
            truckId,
        };

        dispatch(changeUserSelection(dispatchId, tourId, changes));
    };

    const renderTruckOption = (option: TruckOptionT, _?: string, callbacks?: { onCloseOverlay: () => void }) => {
        return (
            <AssignOptionLabel
                assetId={option.id}
                isInvalid={!option?.valid}
                model={option.model}
                plateNumber={option.plateNumber}
                price={t('assignment.rate', { rate: option.ratePerKm })}
                isAssetUtilizationLoading={false}
                assetUtilizationPercent={option.utilization}
                carrierUtilizationPercent={option.carrierUtilizationPercent}
                isCarrierUtilizationLoading={option.isCarrierUtilizationLoading}
                linkedAsset={trailerById[option.linkedAssetId as AssetIdT] || null}
                onSelectLink={handleSelectLinkByTruck}
                onCloseOverlay={callbacks?.onCloseOverlay}
            />
        );
    };

    const handleSelectLinkByTrailer = (trailerId: TrailerIdT) => {
        const linkedTruckId = vehicleLinks.byTrailerId[trailerId] || null;

        formik.setValues((values) => {
            return {
                ...values,
                [FieldsEnum.truckId]: linkedTruckId || values[FieldsEnum.truckId],
                [FieldsEnum.trailerId]: trailerId,
            };
        });

        // Set meta => touched true
        setTimeout(() => {
            formik.setTouched({
                ...formik.touched,
                [FieldsEnum.truckId]: true,
                [FieldsEnum.trailerId]: true,
            });
        }, 0);

        const changes: Partial<UserSelectionT> = {
            trailerId,
            truckId: linkedTruckId,
        };

        dispatch(changeUserSelection(dispatchId, tourId, changes));
    };

    const renderTrailerOption = (option: TrailerOptionT, _?: string, callbacks?: { onCloseOverlay: () => void }) => {
        return (
            <AssignOptionLabel
                assetId={option.id}
                isInvalid={!option?.valid}
                model={option.model}
                plateNumber={option.plateNumber}
                price={t('assignment.rate', { rate: option.ratePerKm })}
                isAssetUtilizationLoading={false}
                assetUtilizationPercent={option.utilization}
                carrierUtilizationPercent={option.carrierUtilizationPercent}
                isCarrierUtilizationLoading={option.isCarrierUtilizationLoading}
                linkedAsset={truckById[option.linkedAssetId as AssetIdT] || null}
                onSelectLink={handleSelectLinkByTrailer}
                onCloseOverlay={callbacks?.onCloseOverlay}
            />
        );
    };

    const handleSelectTruck = (truckId: TruckIdT | null) => {
        const formValueChanges: Partial<FormValuesT> = {
            [FieldsEnum.truckId]: truckId,
        };

        formik.setValues((prevValues) => {
            return {
                ...prevValues,
                ...formValueChanges,
            };
        });

        // Set meta => touched true
        setTimeout(() => {
            formik.setTouched({
                ...formik.touched,
                ...mapValues(formValueChanges, () => true),
            });
        }, 0);

        const changes: Partial<UserSelectionT> = {
            truckId,
        };
        dispatch(changeUserSelection(dispatchId, tourId, changes));
    };

    const renderTruckTriggerIcon =
        (selectedTruckId: TruckIdT | null, selectedTrailerId: TrailerIdT | null) => (iconMeta: IconMetaT) => {
            const isLinked =
                !!selectedTruckId &&
                !!selectedTrailerId &&
                vehicleLinks.byTruckId[selectedTruckId] === selectedTrailerId;
            if (isLinked) {
                return <AssetLinkIcon {...AssetLinkIconProps.getControlProps(iconMeta)} />;
            }

            return <TruckIcon {...TruckIconProps.getControlProps(iconMeta)} />;
        };

    const renderTruckTrigger = (option: TruckOptionT | undefined, placeholder?: React.ReactNode) => {
        if (!option) {
            return placeholder;
        }

        let resetTooltipNode: React.ReactNode;
        if (isDisabledTruckSuggest) {
            resetTooltipNode = t('assignment.tooltips.cancel-truck-transport-order');
        } else if (selectedTrailerId === vehicleLinks.byTruckId[option.id]) {
            resetTooltipNode = t('assignment.tooltips.unlink-truck');
        } else {
            resetTooltipNode = t('assignment.tooltips.clear-truck');
        }

        return (
            <AssignTriggerLabel
                model={option?.model}
                plateNumber={option?.plateNumber}
                rate={option?.ratePerKm}
                resetTooltipNode={resetTooltipNode}
                isDisabled={isDisabledTruckSuggest}
                onReset={() => {
                    if (isDisabledTruckSuggest) {
                        if (initTruckTransportOrder?.id) {
                            cancelTruckTransportOrderConfirmation.setData({
                                transportOrderId: initTruckTransportOrder.id,
                            });
                        } else {
                            logWarning('Empty initTruckTransportOrder.id!');
                        }
                    } else {
                        handleSelectTruck(null);
                    }
                }}
                onOpenDetails={() => {
                    openLeftSidebar(
                        {
                            type: CommonSidebarsTypeEnum.truckDetails,
                            truckId: option?.id,
                            partnerId: option?.carrierId || '',
                            isReadOnly: true,
                            isDisableShowOnMap: true,
                        },
                        {
                            isForceShowBackAction: true,
                        },
                    );
                }}
            />
        );
    };

    const handleSelectTrailer = (trailerId: TrailerIdT | null) => {
        const formValueChanges: Partial<FormValuesT> = {
            [FieldsEnum.trailerId]: trailerId,
        };

        formik.setValues((prevValues) => {
            return {
                ...prevValues,
                ...formValueChanges,
            };
        });

        // Set meta => touched true
        setTimeout(() => {
            formik.setTouched({
                ...formik.touched,
                ...mapValues(formValueChanges, () => true),
            });
        }, 0);

        const changes: Partial<UserSelectionT> = { trailerId };
        dispatch(changeUserSelection(dispatchId, tourId, changes));
    };

    const renderTrailerTriggerIcon =
        (selectedTrailerId: TrailerIdT | null, selectedTruckId: TruckIdT | null) => (iconMeta: IconMetaT) => {
            const isLinked =
                !!selectedTrailerId &&
                !!selectedTruckId &&
                vehicleLinks.byTrailerId[selectedTrailerId] === selectedTruckId;
            if (isLinked) {
                return <AssetLinkIcon {...AssetLinkIconProps.getControlProps(iconMeta)} />;
            }

            return <TrailerIcon {...TrailerIconProps.getControlProps(iconMeta)} />;
        };

    const renderTrailerTrigger = (option: TrailerOptionT | undefined, placeholder?: React.ReactNode) => {
        if (!option) {
            return placeholder;
        }

        let resetTooltipNode: React.ReactNode;
        if (isDisabledTrailerSuggest) {
            resetTooltipNode = t('assignment.tooltips.cancel-trailer-transport-order');
        } else if (selectedTruckId === vehicleLinks.byTrailerId[option.id]) {
            resetTooltipNode = t('assignment.tooltips.unlink-trailer');
        } else {
            resetTooltipNode = t('assignment.tooltips.clear-trailer');
        }

        return (
            <AssignTriggerLabel
                model={option?.model}
                plateNumber={option?.plateNumber}
                rate={option?.ratePerKm}
                isDisabled={isDisabledTrailerSuggest}
                onReset={() => {
                    if (isDisabledTrailerSuggest) {
                        if (initTrailerTransportOrder?.id) {
                            cancelTrailerTransportOrderConfirmation.setData({
                                transportOrderId: initTrailerTransportOrder.id,
                            });
                        } else {
                            logWarning('Empty initTrailerTransportOrder.id!');
                        }
                    } else {
                        handleSelectTrailer(null);
                    }
                }}
                resetTooltipNode={resetTooltipNode}
                onOpenDetails={() => {
                    openLeftSidebar(
                        {
                            type: CommonSidebarsTypeEnum.trailerDetails,
                            trailerId: option?.id,
                            partnerId: option?.carrierId || '',
                            isReadOnly: true,
                            isDisableShowOnMap: true,
                        },
                        {
                            isForceShowBackAction: true,
                        },
                    );
                }}
            />
        );
    };

    const handleSelectPointToDropTruck = (value: LocationT | null) => {
        const formValueChanges: Partial<FormValuesT> = {
            [FieldsEnum.locationToDropTruck]: value,
        };

        const userSelectionChanges: Partial<UserSelectionT> = {
            pointToDropTruck: convertLocationToPoint(value),
        };

        formik.setValues((formValues) => ({ ...formValues, ...formValueChanges }));

        dispatch(changeUserSelection(dispatchId, tourId, userSelectionChanges));
    };

    const handleSelectPointToDropTrailer = (value: LocationT | null) => {
        const formValueChanges: Partial<FormValuesT> = {
            [FieldsEnum.locationToDropTrailer]: value,
        };
        const userSelectionChanges: Partial<UserSelectionT> = {
            pointToDropTrailer: convertLocationToPoint(value),
        };

        if (isSelectedSameLocations) {
            formValueChanges[FieldsEnum.locationToDropTruck] = value;
            userSelectionChanges.pointToDropTruck = convertLocationToPoint(value);
        }

        formik.setValues((formValues) => ({
            ...formValues,
            ...formValueChanges,
        }));

        dispatch(changeUserSelection(dispatchId, tourId, userSelectionChanges));
    };

    const handleExpire = () => {
        dispatch(changeUserSelection(dispatchId, tourId, {}));
    };

    const hasAsyncErrors = !isEmpty(asyncErrors);

    const isLoadingSubmit = selfCostPredictionRequestStatus.loading || assignmentRequestStatus.loading;
    const isDisabledSubmit = isLoadingSubmit || hasAsyncErrors;

    const utilizationDetails: Array<InfoTableRowT> = React.useMemo(() => {
        const getUtilizationLabel = (afterValue: number | null | undefined, beforeValue: number | null | undefined) => {
            if (!isNumber(afterValue)) {
                return '';
            }

            if (!isNumber(beforeValue)) {
                return afterValue;
            }

            const diff = afterValue - beforeValue;
            return `${beforeValue}% ${diff < 0 ? '-' : '+'} ${Math.abs(diff)}%`;
        };

        const rows: Array<InfoTableRowT> = [];

        const isSameCompany =
            !!selfCostPrediction?.truckCarrier?.id &&
            !!selfCostPrediction?.trailerCarrier?.id &&
            selfCostPrediction?.truckCarrier?.id === selfCostPrediction?.trailerCarrier?.id;

        if (isSameCompany && selfCostPrediction?.truckCarrier) {
            rows.push({
                icon: <FlagIcon countryCode={selfCostPrediction?.truckCarrier?.country} />,
                name: t('assignment.utilization-details.train-carrier', {
                    companyName: selfCostPrediction?.truckCarrier?.name,
                }),
                rightNode: isNumber(selfCostPrediction?.truckCarrierUtilizationBefore) ? (
                    <ColoredStatusLabel
                        isWithoutVerticalPaddings
                        label={t('assignment.utilization-details.fleet-utilization', {
                            utilization: getUtilizationLabel(
                                selfCostPrediction?.truckCarrierUtilizationAfter,
                                selfCostPrediction?.truckCarrierUtilizationBefore,
                            ),
                        })}
                        color={getFleetUtilizationColor(selfCostPrediction?.truckCarrierUtilizationBefore)}
                    />
                ) : null,
                isBoldValue: true,
            });
        }

        if (!isSameCompany && selfCostPrediction?.truckCarrier) {
            rows.push({
                icon: <FlagIcon countryCode={selfCostPrediction?.truckCarrier?.country} />,
                name: t('assignment.utilization-details.truck-carrier', {
                    companyName: selfCostPrediction?.truckCarrier?.name,
                }),
                rightNode: isNumber(selfCostPrediction?.truckCarrierUtilizationBefore) ? (
                    <ColoredStatusLabel
                        isWithoutVerticalPaddings
                        label={t('assignment.utilization-details.fleet-utilization', {
                            utilization: getUtilizationLabel(
                                selfCostPrediction?.truckCarrierUtilizationAfter,
                                selfCostPrediction?.truckCarrierUtilizationBefore,
                            ),
                        })}
                        color={getFleetUtilizationColor(selfCostPrediction?.truckCarrierUtilizationBefore)}
                    />
                ) : null,
                isBoldValue: true,
            });
        }

        if (!isSameCompany && selfCostPrediction?.trailerCarrier) {
            rows.push({
                icon: <FlagIcon countryCode={selfCostPrediction?.trailerCarrier?.country} />,
                name: t('assignment.utilization-details.trailer-carrier', {
                    companyName: selfCostPrediction?.trailerCarrier?.name,
                }),
                rightNode: isNumber(selfCostPrediction?.trailerCarrierUtilizationBefore) ? (
                    <ColoredStatusLabel
                        isWithoutVerticalPaddings
                        label={t('assignment.utilization-details.fleet-utilization', {
                            utilization: getUtilizationLabel(
                                selfCostPrediction?.trailerCarrierUtilizationAfter,
                                selfCostPrediction?.trailerCarrierUtilizationBefore,
                            ),
                        })}
                        color={getFleetUtilizationColor(selfCostPrediction?.trailerCarrierUtilizationBefore)}
                    />
                ) : null,
                isBoldValue: true,
            });
        }

        const truckCarrier = truckById[selectedTruckId as TruckIdT];
        if (truckCarrier && isNumber(selfCostPrediction?.truckUtilizationBefore)) {
            rows.push({
                icon: (
                    <TruckIcon
                        size={DEFAULT_ICON_SIZE}
                        strokeColor={StyleGuideColorsEnum.brandDark}
                        fillOpacity={0.3}
                        fillColor={StyleGuideColorsEnum.brandAccent}
                    />
                ),
                name: <AssetLabelFormatter model={truckCarrier.model} plateNumber={truckCarrier.plateNumber} />,
                rightNode: isNumber(selfCostPrediction?.truckUtilizationBefore) ? (
                    <ColoredStatusLabel
                        isWithoutVerticalPaddings
                        label={t('assignment.utilization-details.asset-utilization', {
                            utilization: getUtilizationLabel(
                                selfCostPrediction?.truckUtilizationAfter,
                                selfCostPrediction?.truckUtilizationBefore,
                            ),
                        })}
                        color={getAssetUtilizationColor(selfCostPrediction?.truckUtilizationBefore)}
                    />
                ) : null,
                isBoldValue: true,
            });
        }

        const trailerCarrier = trailerById[selectedTrailerId as TrailerIdT];
        if (trailerCarrier && isNumber(selfCostPrediction?.trailerUtilizationBefore)) {
            rows.push({
                icon: (
                    <TrailerIcon
                        size={DEFAULT_ICON_SIZE}
                        strokeColor={StyleGuideColorsEnum.brandDark}
                        fillOpacity={0.3}
                        fillColor={StyleGuideColorsEnum.brandAccent}
                    />
                ),
                name: <AssetLabelFormatter model={trailerCarrier?.model} plateNumber={trailerCarrier?.plateNumber} />,
                rightNode: isNumber(selfCostPrediction?.trailerUtilizationBefore) ? (
                    <ColoredStatusLabel
                        isWithoutVerticalPaddings
                        label={t('assignment.utilization-details.asset-utilization', {
                            utilization: getUtilizationLabel(
                                selfCostPrediction?.trailerUtilizationAfter,
                                selfCostPrediction?.trailerUtilizationBefore,
                            ),
                        })}
                        color={getAssetUtilizationColor(selfCostPrediction?.trailerUtilizationBefore)}
                    />
                ) : null,
                isBoldValue: true,
            });
        }

        return rows;
    }, [selfCostPrediction, truckById, selectedTruckId, trailerById, selectedTrailerId]);

    const truckWarning = React.useMemo((): string | null => {
        const linkedTruckId = vehicleLinks.byTrailerId[selectedTrailerId as TrailerIdT] || null;
        if (!linkedTruckId) {
            return null;
        }

        if (isDisabledTruckSuggest) {
            return null;
        }

        if (linkedTruckId === selectedTruckId) {
            return null;
        }

        const linkedTruck = truckById[linkedTruckId] || null;
        if (!linkedTruck) {
            return null;
        }

        // @ts-expect-error wrong types for formik
        return (
            <span>
                {t('assignment.warnings.has-linked-truck', {
                    truckLabel: [linkedTruck.plateNumber, linkedTruck.model].filter(Boolean).join(' / '),
                })}{' '}
                <Link
                    onClick={() => {
                        if (selectedTrailerId) {
                            handleSelectLinkByTrailer(selectedTrailerId);
                        }
                    }}
                    theme={LinkThemeEnum.boldOrange}
                >
                    {t('assignment.warnings.has-linked-truck-link')}
                </Link>
            </span>
        );
    }, [t, selectedTruckId, selectedTrailerId, vehicleLinks, truckById, isDisabledTruckSuggest]);

    const trailerWarning = React.useMemo((): string | null => {
        const linkedTrailerId = vehicleLinks.byTruckId[selectedTruckId as TruckIdT] || null;
        if (!linkedTrailerId) {
            return null;
        }

        if (isDisabledTrailerSuggest) {
            return null;
        }

        if (linkedTrailerId === selectedTrailerId) {
            return null;
        }

        const linkedTrailer = trailerById[linkedTrailerId] || null;
        if (!linkedTrailer) {
            return null;
        }

        // @ts-expect-error wrong types for formik
        return (
            <span>
                {t('assignment.warnings.has-linked-trailer', {
                    trailerLabel: [linkedTrailer.plateNumber, linkedTrailer.model].filter(Boolean).join(' / '),
                })}{' '}
                <Link
                    onClick={() => {
                        if (selectedTruckId) {
                            handleSelectLinkByTruck(selectedTruckId);
                        }
                    }}
                    theme={LinkThemeEnum.boldOrange}
                >
                    {t('assignment.warnings.has-linked-trailer-link')}
                </Link>
            </span>
        );
    }, [t, selectedTruckId, selectedTrailerId, vehicleLinks, trailerById, isDisabledTrailerSuggest]);

    return (
        <div className={cx('wrap')}>
            <form onSubmit={formik.handleSubmit}>
                <div className={cx('form')}>
                    <FormikField
                        name={FieldsEnum.truckId}
                        error={formik.errors[FieldsEnum.truckId]}
                        meta={formik.getFieldMeta(FieldsEnum.truckId)}
                        label={t('assignment.fields.truck.label')}
                        setFieldValue={formik.setFieldValue}
                        setFieldTouched={formik.setFieldTouched}
                        asyncError={asyncFormErrors[FieldsEnum.truckId]}
                        resetAsyncError={resetAsyncFormErrors}
                        isForceShowWarning
                        warning={truckWarning}
                    >
                        {(props) => (
                            <DropdownSearchInput<TruckOptionT, TruckIdT | null>
                                selectedValue={formik.values[FieldsEnum.truckId]}
                                options={trucksOptions}
                                onSelect={handleSelectTruck}
                                renderTrigger={renderTruckTrigger}
                                renderOption={renderTruckOption}
                                getOptionValue={getOptionValue}
                                isDisabled={isDisabledTruckSuggest}
                                isLoading={truckSuggestRequestStatus.loading}
                                onChangeQuery={(text) => {
                                    if (!dispatchId || !tourId) {
                                        return;
                                    }

                                    dispatch(
                                        findTruck({
                                            dispatchId,
                                            tourId,
                                            plateNumber: text,
                                            trailerId: formik.values[FieldsEnum.trailerId],
                                            isIgnoreCertificates: userSelection.isIgnoreCertificates,
                                            isIgnoreEmissionStandard: userSelection.isIgnoreEmissionStandard,
                                            pointToDropTrailer: userSelection.pointToDropTrailer,
                                            pointToDropTruck: userSelection.pointToDropTruck,
                                        }),
                                    );
                                }}
                                renderLeftIcon={renderTruckTriggerIcon(selectedTruckId, selectedTrailerId)}
                                onBlur={props.onBlur}
                                onFocus={props.onFocus}
                                hasWarning={props.hasWarning}
                                hasError={props.hasError}
                                overlayPosition={DropdownOverlayPositionEnum.bottomLeft}
                                inputPlaceholder={t('assignment.fields.truck.input-placeholder')}
                                placeholder={t('assignment.fields.truck.placeholder')}
                                optionClassName={cx('option')}
                            />
                        )}
                    </FormikField>
                    <FormikField
                        name={FieldsEnum.trailerId}
                        error={formik.errors[FieldsEnum.trailerId]}
                        meta={formik.getFieldMeta(FieldsEnum.trailerId)}
                        label={t('assignment.fields.trailer.label')}
                        setFieldValue={formik.setFieldValue}
                        setFieldTouched={formik.setFieldTouched}
                        asyncError={asyncFormErrors[FieldsEnum.trailerId]}
                        resetAsyncError={resetAsyncFormErrors}
                        isForceShowWarning
                        warning={trailerWarning}
                    >
                        {(props) => (
                            <DropdownSearchInput<TrailerOptionT, TrailerIdT | null>
                                selectedValue={formik.values[FieldsEnum.trailerId]}
                                options={trailersOptions}
                                onSelect={handleSelectTrailer}
                                renderTrigger={renderTrailerTrigger}
                                renderOption={renderTrailerOption}
                                isDisabled={isDisabledTrailerSuggest}
                                isLoading={trailerSuggestRequestStatus.loading}
                                getOptionValue={getOptionValue}
                                onChangeQuery={(text) => {
                                    if (!dispatchId || !tourId) {
                                        return;
                                    }

                                    dispatch(
                                        findTrailer({
                                            dispatchId,
                                            tourId,
                                            plateNumber: text,
                                            truckId: formik.values[FieldsEnum.truckId],
                                            isIgnoreCertificates: userSelection.isIgnoreCertificates,
                                            isIgnoreEmissionStandard: userSelection.isIgnoreEmissionStandard,
                                            pointToDropTrailer: userSelection.pointToDropTrailer,
                                            pointToDropTruck: userSelection.pointToDropTruck,
                                        }),
                                    );
                                }}
                                renderLeftIcon={renderTrailerTriggerIcon(selectedTrailerId, selectedTruckId)}
                                onBlur={props.onBlur}
                                onFocus={props.onFocus}
                                hasWarning={props.hasWarning}
                                hasError={props.hasError}
                                overlayPosition={DropdownOverlayPositionEnum.bottomLeft}
                                inputPlaceholder={t('assignment.fields.trailer.input-placeholder')}
                                placeholder={t('assignment.fields.trailer.placeholder')}
                                optionClassName={cx('option')}
                            />
                        )}
                    </FormikField>
                    <FormikField
                        name={FieldsEnum.locationToDropTrailer}
                        error={formik.errors[FieldsEnum.locationToDropTrailer]}
                        meta={formik.getFieldMeta(FieldsEnum.locationToDropTrailer)}
                        label={t('assignment.fields.point-to-drop-trailer.label')}
                        setFieldValue={formik.setFieldValue}
                        setFieldTouched={formik.setFieldTouched}
                    >
                        {(props) => (
                            <GeoSuggest
                                name={FieldsEnum.locationToDropTrailer}
                                value={formik.values[FieldsEnum.locationToDropTrailer]}
                                placeholder={t('assignment.fields.point-to-drop-trailer.placeholder')}
                                renderLeftIcon={renderTrailerDropOffIcon(
                                    formik.values[FieldsEnum.locationToDropTrailer]
                                        ? 'assignment-input-selected'
                                        : 'assignment-input-normal',
                                    initPayloadDropPoint,
                                    formik.values[FieldsEnum.locationToDropTruck],
                                    formik.values[FieldsEnum.locationToDropTrailer],
                                )}
                                onChange={handleSelectPointToDropTrailer}
                                hasError={props.hasError}
                                hasWarning={props.hasWarning}
                                onBlur={props.onBlur}
                                testSelector="point-to-drop-trailer"
                                isDisabled={!formik.values[FieldsEnum.trailerId]}
                                showClearControl={!!formik.values[FieldsEnum.locationToDropTrailer]}
                                overlayPosition="top"
                                renderRightNode={() =>
                                    !checkIsSameLocation(
                                        initTrailerDropPoint?.location || null,
                                        formik.values[FieldsEnum.locationToDropTrailer],
                                    ) && (
                                        <TransparentTrigger
                                            spaces="xs"
                                            onClick={() => {
                                                handleSelectPointToDropTrailer(initTrailerDropPoint?.location || null);
                                            }}
                                            leftIcon={
                                                <NumberIcon
                                                    fillColor={StyleGuideColorsEnum.charcoal}
                                                    number={(initPayloadDropPoint?.index || 0) + 1}
                                                />
                                            }
                                            reflectionTheme={ReflectionThemeEnum.halfTransparentLight}
                                        />
                                    )
                                }
                            />
                        )}
                    </FormikField>
                    <FormikField
                        name={FieldsEnum.locationToDropTruck}
                        error={formik.errors[FieldsEnum.locationToDropTruck]}
                        meta={formik.getFieldMeta(FieldsEnum.locationToDropTruck)}
                        label={t('assignment.fields.point-to-drop-truck.label')}
                        setFieldValue={formik.setFieldValue}
                        setFieldTouched={formik.setFieldTouched}
                    >
                        {(props) => (
                            <GeoSuggest
                                name={FieldsEnum.locationToDropTruck}
                                value={formik.values[FieldsEnum.locationToDropTruck]}
                                placeholder={t('assignment.fields.point-to-drop-truck.placeholder')}
                                renderLeftIcon={renderTruckDropOffIcon(
                                    formik.values[FieldsEnum.locationToDropTruck]
                                        ? 'assignment-input-selected'
                                        : 'assignment-input-normal',
                                    initPayloadDropPoint,
                                    formik.values[FieldsEnum.locationToDropTruck],
                                    formik.values[FieldsEnum.locationToDropTrailer],
                                )}
                                onChange={handleSelectPointToDropTruck}
                                hasError={props.hasError}
                                hasWarning={props.hasWarning}
                                onBlur={props.onBlur}
                                isDisabled={!formik.values[FieldsEnum.truckId]}
                                testSelector="point-to-drop-truck"
                                showClearControl={!!formik.values[FieldsEnum.locationToDropTruck]}
                                overlayPosition="top"
                                renderRightNode={() =>
                                    !checkIsSameLocation(
                                        initTruckDropPoint?.location || null,
                                        formik.values[FieldsEnum.locationToDropTruck],
                                    ) && (
                                        <TransparentTrigger
                                            spaces="xs"
                                            onClick={() => {
                                                handleSelectPointToDropTruck(initTruckDropPoint?.location || null);
                                            }}
                                            leftIcon={
                                                <NumberIcon
                                                    fillColor={StyleGuideColorsEnum.charcoal}
                                                    number={(initPayloadDropPoint?.index || 0) + 1}
                                                />
                                            }
                                            reflectionTheme={ReflectionThemeEnum.halfTransparentLight}
                                        />
                                    )
                                }
                            />
                        )}
                    </FormikField>
                </div>
                <TourTypeTabs
                    className={cx('tout-type-tabs')}
                    dispatchDetails={dispatchDetails}
                    isLoading={selfCostPredictionRequestStatus.loading}
                    selfCostPrediction={selfCostPrediction}
                    selectedTourTypeTab={selectedTourTypeTab}
                    onSelectTourTypeTab={onSelectTourTypeTab}
                />
                {(selfCostPrediction || selfCostPredictionRequestStatus.loading) && (
                    <div className={cx('self-cost-prediction')}>
                        <InfoTable
                            shouldRenderIcons
                            rows={utilizationDetails}
                            className={cx('table', 'table--utilization')}
                        />
                        <AssetTypeTabs
                            className={cx('asset-type-tabs')}
                            selfCostPrediction={selfCostPrediction}
                            selectedTourTypeTab={selectedTourTypeTab}
                            selectedAssetType={selectedAssetType}
                            onSelectAssetType={setSelectedAssetType}
                        />
                        {selectedAssetType === AssetTypeTabsEnum.train &&
                            selectedTourTypeTab === TourTypeTabsEnum.current && (
                                <ReceiptPriceDetails
                                    label={t('assignment.costs-type.train')}
                                    className={cx('receipt')}
                                    costs={selfCostPrediction?.calculatedSumCost}
                                />
                            )}
                        {selectedAssetType === AssetTypeTabsEnum.truck &&
                            selectedTourTypeTab === TourTypeTabsEnum.current && (
                                <ReceiptPriceDetails
                                    label={t('assignment.costs-type.truck')}
                                    className={cx('receipt')}
                                    costs={selfCostPrediction?.calculatedTruckCost}
                                />
                            )}
                        {selectedAssetType === AssetTypeTabsEnum.trailer &&
                            selectedTourTypeTab === TourTypeTabsEnum.current && (
                                <ReceiptPriceDetails
                                    label={t('assignment.costs-type.trailer')}
                                    className={cx('receipt')}
                                    costs={selfCostPrediction?.calculatedTrailerCost}
                                    isHideFuelPrice
                                />
                            )}
                        {selectedAssetType === AssetTypeTabsEnum.truck &&
                            selectedTourTypeTab === TourTypeTabsEnum.next && (
                                <ReceiptPriceDetails
                                    label={
                                        <Link
                                            target="_blank"
                                            theme={LinkThemeEnum.boldBrandDark}
                                            to={urlFactory.partnerTransportOrderDetails(
                                                PartnerTypeEnum.carrier,
                                                selfCostPrediction?.truckCarrier?.id || '-',
                                                selfCostPrediction?.truckNextTransportationOrderInfo?.id || '-',
                                            )}
                                        >
                                            {t('assignment.costs-type.transport-order', {
                                                transportOrderNumber:
                                                    selfCostPrediction?.truckNextTransportationOrderInfo?.number,
                                            })}
                                        </Link>
                                    }
                                    className={cx('receipt')}
                                    costs={selfCostPrediction?.truckNextTourNewCostDetails}
                                    prevCosts={selfCostPrediction?.truckNextTourOldCostDetails}
                                />
                            )}
                        {selectedAssetType === AssetTypeTabsEnum.trailer &&
                            selectedTourTypeTab === TourTypeTabsEnum.next && (
                                <ReceiptPriceDetails
                                    label={
                                        <Link
                                            target="_blank"
                                            theme={LinkThemeEnum.boldBrandDark}
                                            to={urlFactory.partnerTransportOrderDetails(
                                                PartnerTypeEnum.carrier,
                                                selfCostPrediction?.trailerCarrier?.id || '-',
                                                selfCostPrediction?.trailerNextTransportationOrderInfo?.id || '-',
                                            )}
                                        >
                                            {t('assignment.costs-type.transport-order', {
                                                transportOrderNumber:
                                                    selfCostPrediction?.trailerNextTransportationOrderInfo?.number,
                                            })}
                                        </Link>
                                    }
                                    className={cx('receipt')}
                                    costs={selfCostPrediction?.trailerNextTourNewCostDetails}
                                    prevCosts={selfCostPrediction?.trailerNextTourOldCostDetails}
                                />
                            )}
                        {selectedAssetType === AssetTypeTabsEnum.train &&
                            selectedTourTypeTab === TourTypeTabsEnum.next && (
                                <ReceiptPriceDetails
                                    label={
                                        <Link
                                            target="_blank"
                                            theme={LinkThemeEnum.boldBrandDark}
                                            to={urlFactory.partnerTransportOrderDetails(
                                                PartnerTypeEnum.carrier,
                                                selfCostPrediction?.truckCarrier?.id || '-',
                                                selfCostPrediction?.truckNextTransportationOrderInfo?.id || '-',
                                            )}
                                        >
                                            {t('assignment.costs-type.transport-order', {
                                                transportOrderNumber:
                                                    selfCostPrediction?.truckNextTransportationOrderInfo?.number,
                                            })}
                                        </Link>
                                    }
                                    className={cx('receipt')}
                                    costs={selfCostPrediction?.truckNextTourNewCostDetails}
                                    prevCosts={selfCostPrediction?.truckNextTourOldCostDetails}
                                />
                            )}
                        {selectedAssetType === AssetTypeTabsEnum.train && (
                            <MileageInfoTable
                                totalDistance={selfCostPrediction?.calculatedSumCost?.totalDistance || null}
                                payloadDistance={selfCostPrediction?.calculatedSumCost?.payloadDistance || null}
                                deadheadDistance={selfCostPrediction?.calculatedSumCost?.deadheadDistance || null}
                                isNotActual={!formik.values[FieldsEnum.truckId] || !formik.values[FieldsEnum.trailerId]}
                            />
                        )}
                        {selectedAssetType === AssetTypeTabsEnum.truck && (
                            <MileageInfoTable
                                totalDistance={selfCostPrediction?.calculatedTruckCost?.totalDistance || null}
                                payloadDistance={selfCostPrediction?.calculatedTruckCost?.payloadDistance || null}
                                deadheadDistance={selfCostPrediction?.calculatedTruckCost?.deadheadDistance || null}
                                isNotActual={!formik.values[FieldsEnum.truckId] || !formik.values[FieldsEnum.trailerId]}
                            />
                        )}
                        {selectedAssetType === AssetTypeTabsEnum.trailer && (
                            <MileageInfoTable
                                totalDistance={selfCostPrediction?.calculatedTrailerCost?.totalDistance || null}
                                payloadDistance={selfCostPrediction?.calculatedTrailerCost?.payloadDistance || null}
                                deadheadDistance={selfCostPrediction?.calculatedTrailerCost?.deadheadDistance || null}
                                isNotActual={!formik.values[FieldsEnum.truckId] || !formik.values[FieldsEnum.trailerId]}
                            />
                        )}
                        {(selfCostPredictionRequestStatus.loading || selfCostPredictionRequestStatus.error) && (
                            <>
                                <div className={cx('self-cost-prediction__overlay')} />
                                {selfCostPredictionRequestStatus.loading && (
                                    <div className={cx('self-cost-prediction__loader')}>
                                        <ControlLoaderLabel isInvert>
                                            {t(
                                                !selfCostPrediction
                                                    ? 'assignment.loader.calculating'
                                                    : 'assignment.loader.re-calculating',
                                            )}
                                        </ControlLoaderLabel>
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                )}
                <ScrollToFirstError submitCount={formik.submitCount} errors={formik.errors} />
                <FooterSideBarLayout hasPaddings>
                    <div className={cx('actions')}>
                        <Button
                            theme={ButtonThemeEnum.secondary}
                            className={cx('action', 'action--cancel')}
                            onClick={onCancel}
                        >
                            {t('assignment.actions.cancel')}
                        </Button>
                        <Button
                            isFullWidth
                            theme={ButtonThemeEnum.primary}
                            isDisabled={isDisabledSubmit}
                            className={cx('action', 'action--assign')}
                            isLoading={isLoadingSubmit}
                            type="submit"
                            leftIcon={<div />}
                            rightIcon={
                                <ButtonTimer
                                    showTime={MS_IN_MIN}
                                    expireTime={selfCostPrediction?.expireTime || null}
                                    tooltipMessage={t('assignment.time-left-to-accept-cost')}
                                    onExpire={handleExpire}
                                />
                            }
                        >
                            {t('assignment.actions.assign')}
                        </Button>
                    </div>
                </FooterSideBarLayout>
            </form>
            <LongDeadheadConfirmation
                data={longDeadheadConfirmation.data}
                requestStatus={assignmentRequestStatus}
                onClose={longDeadheadConfirmation.onClose}
                onCancel={longDeadheadConfirmation.onCancel}
                onConfirm={({ dispatchId, tourId, assignmentContextId }) => {
                    dispatch(assign(dispatchId, tourId, assignmentContextId));
                }}
            />
            <CancelTrailerTransportOrderConfirmation
                data={cancelTrailerTransportOrderConfirmation.data}
                requestStatus={updateTrailerTransportOrderStatusRequest}
                onClose={cancelTrailerTransportOrderConfirmation.onClose}
                onCancel={cancelTrailerTransportOrderConfirmation.onCancel}
                onConfirm={handleConfirmCancelTrailerTransportOrder}
            />
            <CancelTruckTransportOrderConfirmation
                data={cancelTruckTransportOrderConfirmation.data}
                requestStatus={updateTruckTransportOrderStatusRequest}
                onClose={cancelTruckTransportOrderConfirmation.onClose}
                onCancel={cancelTruckTransportOrderConfirmation.onCancel}
                onConfirm={handleConfirmCancelTruckTransportOrder}
            />
        </div>
    );
};

export default AssignmentForm;
